import React, {useState, FC} from "react";
import styles from "./TextArea.module.scss";

/**
 * Type for TextAreaProps
 */
type TextAreaProps = {
  id: string;
  testId?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  maxCharacters?: number;
  changeHandler?: (element: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextArea: FC<TextAreaProps> = ({
  id = "",
  testId = "text-area",
  label = "",
  placeholder = "",
  value = "",
  changeHandler,
  maxCharacters,
}) => {
  // const [textAreaHeight, setTextAreaHeight] = useState<number | null>(null);
  const [isFocus, setIsFocus] = useState<boolean> (false)

  function textAreaChangeHandler(
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    event.currentTarget.value = event.currentTarget.value.trimStart();
    changeHandler?.(event);
  }
  const onFocusHandler = () => {
    setIsFocus(true)
  }
  // TODO:Need to implement
  // function handleTextareaResize(event: React.ChangeEvent<HTMLTextAreaElement>) {
  //   const lineHeight = 20;
  //   const rows = Math.ceil((event.target.scrollHeight - lineHeight) / lineHeight);
  //   setTextAreaHeight(rows * lineHeight);
  //   console.log(textAreaHeight)
  // }

  return (
    <div className={`text_area_wrapper ${styles.text_area_wrapper}`}>
      <div className={`input_wrapper ${styles.input_wrapper}`} onClick={onFocusHandler}>
        <div className={`lbl_wrapper ${styles.lbl_wrapper}`}>
          <div className={`lbl ${styles.lbl} ${isFocus ? 'on_focus' : null}`}>{label}</div>
        </div>
        <textarea
          id={id}
          data-testid={testId}
          className={`text_area ${styles.text_area}`}
          placeholder={placeholder}
          defaultValue={value}
          maxLength={maxCharacters}
          onChange={textAreaChangeHandler}
          // TODO:
          // style={{ height: textAreaHeight ? `${textAreaHeight}px` : '186px', minHeight: '186px' }}
          // onInput={handleTextareaResize}
        />
      </div>
    </div>
  );
};

export default TextArea;
