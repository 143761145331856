import React, { useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface LanguageWrapperProps {
  children: ReactNode; // Use ReactNode for more flexibility
}

const LanguageWrapper: React.FC<LanguageWrapperProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const selectedLang = useSelector(
    (state: RootState) => state.language.currentLanguage,
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang, i18n]);

  return <>{children}</>;
};

export default LanguageWrapper;
