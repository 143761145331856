import React from "react";
import styles from "./GoalAndActionPlans.module.scss";
import Goals from "./Goals/Goals";
import ProgressNotes from "./ProgressNotes/ProgressNotes";
import GoalActionPlanDocument from "./Documents/GoalActionPlanDocument";

interface GoalAndActionPlansProps {
  activeLink: string;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
}

const GoalAndActionPlans: React.FC<GoalAndActionPlansProps> = ({
  refMap,
}) => {
  return (
    <div className={styles.GAP_wrapper} data-testid="goals_action_plan_testID">
      <div className={`content_inner ${styles.GAP_inner_wrapper}`}>
        <div className={styles.section_wrapper} ref={refMap["goals"]}>
          <Goals />
        </div>
        <div className={styles.section_wrapper} ref={refMap["documents"]}>
          <GoalActionPlanDocument />
        </div>
        <div className={styles.section_wrapper} ref={refMap["notes"]}>
          <ProgressNotes />
        </div>
      </div>
    </div>
  );
};

export default GoalAndActionPlans;
