import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import styles from "./HATVDetails.module.scss";
import AILogoLight from "../../../assets/images/LightTheme/ai_logo_1.svg";
import AILogoDark from "../../../assets/images/DarkTheme/ai_logo_1.svg";
import HATVViolationCard from "./HATVViolationCard/HATVViolationCard";
import { useTranslation } from "react-i18next";
import FollowUpSection from "../FollowUpQestions/FollowUpQuestion";
import { RootState } from "../../../redux/store";
import { DIGIT_HUNDRED } from "../../../constants/labelConstants";

interface Profile {
  aboNumber: string;
  aboName: string;
  aboCountry: string;
  flag: string;
  kpis: string;
  affiliateCode: string;
}

interface CustomResponse {
  question: string;
  response: string;
}

interface Choice {
  key: string;
  desc: string;
  utterances?: string | null;
}

interface HATVDetailsProps {
  profile: Profile;
  onBack: () => void;
  contentRef?: React.RefObject<HTMLDivElement>; 
  isSelected: boolean;
  followUpChoices: Choice[];
  responseData: CustomResponse[];
  setResponseData: React.Dispatch<React.SetStateAction<CustomResponse[]>>;
}

const HATVDetails: React.FC<HATVDetailsProps> = ({
  profile,
  onBack,
  contentRef,
  followUpChoices,
  responseData,
  setResponseData,
}) => {
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const [isLoading, setIsLoading] = useState(true);
  const [isChatTriggered, setIsChatTriggered] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsChatTriggered(true);
    }, DIGIT_HUNDRED); // Simulate loading time for chatbot-like experience
  }, []);

  return (
    <div className={styles.violation_details_container}>
      <div
        className={styles.violation_details_title}
        ref={contentRef}
        style={{
          color: currentTheme === "light" ? "#007775" : "#77CBBF",
          cursor: isChatTriggered ? "default" : "pointer",
        }}
      >
        {t("abnormalViolationMessage", { aboName: profile?.aboName })}
      </div>
      {isLoading && <LoadingAnimation />}
      {isChatTriggered && (
        <>
          <div className={styles.violation_response_textline_wrapper}>
            <img
              src={currentTheme === "light" ? AILogoLight : AILogoDark}
              className={styles.ai_logo}
              alt="logo"
            />
            <div
              className={styles.textline}
              style={{
                color: currentTheme === "light" ? "#2C2C2C" : "#D8D8D9",
              }}
            >
              {t("violation_summary")}
            </div>
          </div>
          <HATVViolationCard profile={profile}></HATVViolationCard>
          {followUpChoices && responseData && setResponseData && (
            <div className={styles.follow_up_section}>
              <FollowUpSection
                affiliateCode={profile.affiliateCode}
                aboNumber={profile.aboNumber}
                followUpChoices={followUpChoices}
                responseData={responseData}
                setResponseData={setResponseData}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HATVDetails;
