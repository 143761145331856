import React, { useEffect, useState } from "react";
import styles from "./ProfileSubheader.module.scss";
import isEmpty from "lodash/isEmpty";
import { updateTag } from "../../../redux/slices/tagSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../types/types";
import { ReactComponent as Pipe } from "../../../assets/images/pipe.svg";
import { getGradientBackground } from "../../../utils/getGradientBackground";
import { useTranslation } from "react-i18next";
import { getAwardIcon, getCountryNameFromCode, getAwardNameFromCode } from "../../../utils/awardIconMapping";
import { getPerformanceTag } from "../../../utils/performanceTagUtil";
import { getInitials } from "../../../utils/common";
import {
  useGetAboSummaryDetailsQuery,
  useGetAboAccountManagerQuery,
  useGetAboPerformanceQuery,
  useGetAboAwardQuery,
  useGetABOLOADetailsQuery,
  useGetUplineDiamondInfoQuery,
} from "../../../redux/services/aboInfoBannerDetailsAPI";


interface ProfileSubHeaderProps {
  onAwardCodeChange : (data : string) => void;
}


const ProfileSubHeader: React.FC<ProfileSubHeaderProps> =({ onAwardCodeChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDetails = useSelector((state: any) => state.userDetails.user);
  const [affiliateCode, setAffiliateCode] = useState(
    userDetails?.affiliateCode || 0
  );
  const [aboNumber, setAboNumber] = useState(userDetails?.aboNumber || 0);
  const currentTag = useSelector((state: RootState) => state.tag.currentTag);
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const [AboAwardData, setAboAwardData] = useState<ABOAwardDataType | null>(
    null
  );

  const [aboPerformanceTag, setAboPerformanceTag] =
    useState<ABOPerformanceDataType | null>(null);
  const [summaryDetails, setSummaryDetails] =
    useState<ABOSummaryDataType | null>(null);

  //Summary details API
  const {
    data: aboSummaryDetails,
    isLoading: aboSummaryLoading,
    isError: aboSummaryError,
  } = useGetAboSummaryDetailsQuery({
    affiliateCode: userDetails.affiliateCode,
    aboNumber: userDetails.aboNumber,
  }, { refetchOnMountOrArgChange: true });

  //Account manager API - depends on the Summary details API for the native id.
  const {
    data: aboAccountManager,
    isLoading: aboAccountManagerLoading,
    isError: aboAccountManagerError,
  } = useGetAboAccountManagerQuery(
    { nativeId: aboSummaryDetails?.body?.accountManagerNativeId },
    {
      skip: isEmpty(aboSummaryDetails?.body?.accountManagerNativeId),
      refetchOnMountOrArgChange: true,
    }
  );

  const accountManagerDetails: ABOAccountManagerDataType = isEmpty(
    aboSummaryDetails?.body?.accountManagerNativeId
  )
    ? null
    : aboAccountManager?.body;

  const {
    data: aboLOADetails,
    isLoading: isLoadingLOA,
  } = useGetABOLOADetailsQuery({
    affiliateCode: userDetails.affiliateCode,
    aboNumber: userDetails.aboNumber,
  }, { refetchOnMountOrArgChange: true });
  
  //Award API
  const {
    isLoading: isLoadingAward,
    isError: isAwardError,
    data: awardData,
  } = useGetAboAwardQuery({
    affiliateCode: userDetails.affiliateCode,
    aboNumber: userDetails.aboNumber,
  }, { refetchOnMountOrArgChange: true });

  //ABO performance API
  const {
    isLoading: isLoadingPerformance,
    isError: isPerformanceError,
    data: performanceData,
  } = useGetAboPerformanceQuery({
    affiliateCode: userDetails.affiliateCode,
    aboNumber: userDetails.aboNumber,
  }, { refetchOnMountOrArgChange: true });

  // Upline Diamond API
  const {
    data: uplineDiamondInfo,
    isLoading: isLoadingUplineInfo,
    isError: isUplineInfoError,
  } = useGetUplineDiamondInfoQuery({
    affiliateCode: userDetails.affiliateCode,
    aboNumber: userDetails.aboNumber,
  }, { refetchOnMountOrArgChange: true });

  const background = getGradientBackground({ tag: currentTag });

  const ABOBackground =
    currentTheme === "light" ? background.ABOBackground : "#282828";

  useEffect(() => {
    if (aboSummaryDetails) {
      setSummaryDetails(aboSummaryDetails?.body ?? {});
    }
  }, [aboSummaryDetails]);

  useEffect(() => {
    setAboAwardData(awardData?.body ?? {});
    onAwardCodeChange(awardData?.body?.currentAwardCode ?? null)
  }, [awardData]);

  useEffect(() => {
    setAboPerformanceTag(performanceData?.body ?? {});
  }, [performanceData]);

  useEffect(() => {
    dispatch(
      updateTag(
        getPerformanceTag({
          tagValue: aboPerformanceTag?.aboPerformance ?? undefined,
        })
      )
    );
  }, [dispatch, aboPerformanceTag, currentTheme]);
  const trackingPin = getAwardNameFromCode(AboAwardData?.trackingAwardCode, t);
  const currentPin = getAwardNameFromCode(AboAwardData?.currentAwardCode, t);
  const accountManager = accountManagerDetails?.fullName;
  const nativeId= accountManagerDetails?.nativeId;
  const loaName= aboLOADetails?.body?.loaName || '';
  const uplineDiamond = uplineDiamondInfo?.body?.name || '';

  return (
    <section className={styles.profile_container}>
      { aboSummaryLoading &&
        isLoadingAward &&
        isLoadingPerformance &&
        isLoadingLOA &&
        isLoadingUplineInfo ? (
        <div className={`${styles.profile_wrapper} animate`}></div>
      ) : aboSummaryError ||
        aboAccountManagerError ||
        isAwardError ||
        isPerformanceError ||
        isUplineInfoError ? (
        <div data-testId="error_testID" className={styles.error_text}>{t("no_info_available")}</div>
      ) : (
        <div
          className={`profile_wrapper ${styles.profile_wrapper}`}
          // style={{ backgroundColor: ABOBackground }}
        >
          <div
            className={styles.side_gredient}
            // style={{ background: background?.sideBar }}
          ></div>
          <div className={styles.profile_inner_wrapper}>
            <div className={styles.profile_main_info_wrapper}>
              <div
                className={`profile_image_wrapper ${styles.profile_image_wrapper}`}
                // style={{ background: background?.initials }}
              >
                <div className={styles.profile_image}>
                  {summaryDetails?.name
                    ? getInitials(summaryDetails?.name)
                    : null}
                </div>
              </div>
              <div className={styles.abo_info_name_number}>
                <div className={`abo_name ${styles.abo_name}`}>
                  {summaryDetails?.name}
                </div>
                <div className={styles.abo_number_status}>
                  <span className={`abo_number ${styles.abo_number}`}>
                    #{summaryDetails?.aboNumber}
                  </span>
                  {summaryDetails?.marketCountryCode && (
                    <span className={styles.pipeIcon}>
                      <Pipe />
                    </span>
                  )}
                  <span className={`country ${styles.country}`}>
                    {getCountryNameFromCode(summaryDetails?.marketCountryCode, t)}
                  </span>
                  {/* <span className={styles.abo_status}> //TODO: Uncomment the code if need to show performance tag
                  <StatusTag />
                </span> */}
                </div>
                <div className={`account_name_wrapper  ${styles.account_name_wrapper}`}>
                <span
                    className={`account_name ${styles.account_name}`}
                    data-testid="account_manager_testID"
                  >
                  {" "}
                  {t("sub_header_info.accountManagerName")}{":"}
                </span>
                <span
                  className={`account_name_value ${styles.account_name_value}`}
                  title={accountManager ? accountManager : nativeId ? nativeId : ""}
                >
                  {accountManager ? accountManager : nativeId ? nativeId : "-"}
                </span>
              </div>
              </div>
            </div>
            {/* <div className={styles.info_wrapper}> */}
            <div className={styles.abo_info_account_pin}>
              <div
                className={`field_title_wrapper ${styles.field_title_wrapper}`}
              >
                <span
                  className={`${styles.data_item} ${styles.upline_name}`}
                  data-testid="account_manager_testID"
                >
                  {" "}
                  {t("sub_header_info.uplineDiamond")}
                </span>
                <span
                  className={`${styles.data_item}  ${styles.loaName}`}
                  data-testid="loa_name_testID"
                >
                  {t("sub_header_info.loaName")}
                </span>
                <span
                  className={`${styles.data_item} ${styles.trackingPin}`}
                  data-testid="tracking_pin_testID"
                >
                  {t("sub_header_info.trackingPin")}
                </span>
                <span
                  className={`${styles.data_item} ${styles.currentPin}`}
                  data-testid="current_pin_testID"
                >
                  {t("sub_header_info.currentPin")}
                </span>
              </div>
              <div
                className={`field_data_wrapper ${styles.field_data_wrapper}`}
              >
                <span
                  className={`upline_name ${styles.data_item} ${styles.data_value} ${uplineDiamond && styles.upline_name}`}
                  title={uplineDiamond ? uplineDiamond : ""}
                >
                  {uplineDiamond ? uplineDiamond : "-"}
                </span>
                <span className={`loaName ${styles.data_item} ${styles.data_value} ${loaName && styles.loaName}`} title={loaName ? loaName : ""}>
                  {loaName ? loaName : "-"}
                </span>

                <div
                  className={`${styles.data_item} ${styles.data_value} ${styles.trackingPin_wrapper}`}
                >
                  <span className={styles.awardIcon}>
                    {getAwardIcon(AboAwardData?.trackingAwardName)}
                  </span>
                    <div className={`${styles.trackingPin} ${styles.values}`} title={trackingPin}>
                    {trackingPin}
                    </div>
                </div>
                <div
                  className={`${styles.data_item} ${styles.data_value} ${styles.currentPin_wrapper}`}
                >
                  <span className={styles.awardIcon}>
                    {getAwardIcon(AboAwardData?.currentAwardName)}
                  </span>
                  <div className={`${styles.currentPin} ${styles.values}`} title={currentPin}>
                    {currentPin}
                  </div>
                </div>
              </div>
            </div>           
            {/* </div> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default ProfileSubHeader;
