import React, { ReactNode } from "react";
import styles from "./Tooltip.module.scss"; 

type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
  width?: string; 
  height?: string; 
  bottom?: string;    
  left?: string;
  tipPos?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({ content, children, width = "auto", height = "auto", bottom = "20px", left = "50%", tipPos }) => {
  return (
    <div className={`tooltip_wrapper ${styles.tooltip_wrapper}`}>
      {children}
      <span 
        className={`tooltip ${styles.tooltip} ${tipPos && styles.tip_position}`} 
        style={{ width, height, bottom, left }} // Apply dynamic top and left positions
      >
        <span className={`tooltip_text ${styles.tooltip_text}`}>{content}</span>
      </span>
    </div>
  );
};

export default Tooltip;
