import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import styles from "./Header.module.scss";
import { RootState } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { useParams, Link, useLocation ,useNavigate} from "react-router-dom";
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher";
import EditIconLight from "../../../assets/images/edit_link_light.svg";
import EditIconDark from "../../../assets/images/edit_link_dark.svg";
import AmwayLogoLight from "../../../assets/images/Amway_logo_l.svg";
import AmwayLogoDark from "../../../assets/images/Amway_logo_d.svg";
import BellLight from "../../../assets/images/bell_light.svg";
import BellDark from "../../../assets/images/bell_dark.svg";
import ThemeToggleBtn from "../ThemeToggleBtn/ThemeToggle";
import SearchBar from "../SearchBar/SearchBar";
import { useGetOptionsListQuery } from "../../../redux/services/optionMasterAPI";
import { QUERY_STATUS, countryMappings } from "../../../constants/dataConstants";
import authService from "../../../config/auth.service";
import { HttpStatusCode } from "axios";
import axiosInstance from "../../../config/interceptors/axios.interceptor";
import config from "../../../config/config";
import {
  getFilteredDynamicLinkList,
  getFilteredStaticLinkList,
} from "../../../utils/externalNavigationLinkUtil";
import { useAppSelector } from "../../../redux/hooks";
import LogoutIconDark from "../../../assets/images/DarkTheme/logout_icon.svg";
import LogoutIconLight from "../../../assets/images/LightTheme/logout_icon.svg";
import Icon from "../Icon/Icon";

type HeaderProps = {
  isLoginPage?: boolean;
};

const Header: React.FC<HeaderProps> = ({ isLoginPage = false }) => {
  const history = useLocation();
  const selectedLang = useSelector(
    (state: any) => state.language.currentLanguage
  );
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const userDetails: any = useAppSelector(
    (state: any) => state.userDetails.user
  );

  const { lang } = useParams();
  const { i18n, t } = useTranslation();

  const amwayLogo = currentTheme === "light" ? AmwayLogoLight : AmwayLogoDark;
  const editIcon = currentTheme === "light" ? EditIconLight : EditIconDark;
  const bellIcon = currentTheme === "light" ? BellLight : BellDark;
  const logoutIcon =
    currentTheme === "light" ? LogoutIconLight : LogoutIconDark;

  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);
  const [externalSitesList, setExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);
  const [staticExternalSitesList, setStaticExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);
  const [dynamicExternalSitesList, setDynamicExternalSitesList] = useState<
    externalSiteTypeFomAPI[] | undefined
  >([]);
  const navigate = useNavigate();

  const handleLinkClick = (e: React.MouseEvent, targetPath:string) => {
    e.preventDefault();
    if(history.pathname === targetPath){
      navigate(targetPath, {replace:true});
      window.location.reload(); 
    }else{
      navigate(targetPath, {replace:true});
    }
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const { data } = useGetOptionsListQuery(
    {
      applicationCode: "amw",
      optionMasterName: "siteheader-externalsite",
    },
    { skip: isLoginPage }
  );

  useEffect(() => {
    const resData = data?.body?.options;
    setStaticExternalSitesList(
      getFilteredStaticLinkList(resData, userDetails?.userAffiliateCode)
    );
    setDynamicExternalSitesList(
      getFilteredDynamicLinkList(resData, userDetails.affiliateCode)
    );
  }, [data, userDetails, userDetails.affiliateCode]);

  const appendUTMParams = (url: any, utmParams: any) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(utmParams);

    params.forEach((value, key) => {
      urlObj.searchParams.append(key, value);
    });

    return urlObj.toString();
  };

  useEffect(() => {
    if (history.pathname === "/profile") {
      setExternalSitesList(dynamicExternalSitesList);
    } else {
      setExternalSitesList(staticExternalSitesList);
    }
  }, [history, staticExternalSitesList, dynamicExternalSitesList]);

  const handleClickExternalLink = (
    clickedSiteLinkObj: externalSiteTypeFomAPI
  ) => {
    let siteLink = "";

    if (clickedSiteLinkObj?.hasParameter) {
      siteLink = clickedSiteLinkObj?.code
        ?.replace("{affiliateCode}", userDetails.affiliateCode)
        ?.replace("{aboNumber}", userDetails.aboNumber)
        ?.replace("{aboNumberBase64}", window.btoa(userDetails.aboNumber))
        ?.replace("{locale}", i18n.language);
    } else {
      siteLink = clickedSiteLinkObj?.code ?? "";
    }

    const userNativeId = localStorage.getItem("auth-nativeId");

    const utmCampaign = `${countryMappings[userDetails.userAffiliateCode]}_${selectedLang}_${userNativeId}`;
    const utmTrackingCode = `?utm_source=am_workspace&utm_medium=linkout&utm_campaign=${utmCampaign}`;
    const finalURL =
      clickedSiteLinkObj?.systemCode === "boss"
        ? siteLink
        : appendUTMParams(siteLink, utmTrackingCode);

    window.open(finalURL, "_blank");
  };

  const handleClickLogout = () => {
    const url = `${config.appUrl}/auth/logout`;
    axiosInstance
      .get(url)
      .then((res: any) => {
        if (res && res.data) {
          let result = JSON.parse(JSON.stringify(res.data));
          if (result && result.statusCode === HttpStatusCode.Ok) {
            if (result.head && result.head.authentication) {
              const auth = result.head.authentication;
              if (
                auth.loginSessionStatus === QUERY_STATUS.LOGOUT &&
                auth.userSessionStatus === QUERY_STATUS.LOGOUT
              ) {
                authService.clearAuth();
                window?.heap?.resetIdentity();
                window.location.href = "/login";
                return;
              }
            }
          }
        }
        // authService.clearAuth();
        // window.location.href = "/login";
      })
      .catch((err: any) => {
        console.log(`Logout error: ${err}`);
        authService.clearAuth();
        window?.heap?.resetIdentity();
        window.location.href = "/login";
      });
  };

  return (
    <header
      className={`header_container ${currentTheme} ${styles.header_container} `}
      data-testid="header_component_testId"
    >
      <section className={`top_bar_wrapper ${styles.top_bar_wrapper}`}>
        {!isLoginPage && (
          <nav className={styles.navbar}>
            <ul className={styles.navlist_left}>
              {map(externalSitesList, (siteLinkObj) => (
                <li
                  key={siteLinkObj.code}
                  className={styles.nav_item}
                  onClick={() => handleClickExternalLink(siteLinkObj)}
                >
                  <span className={`label ${styles.label}`}>
                    {t(`header.${siteLinkObj?.value}`)}
                  </span>
                  <img src={editIcon} alt={editIcon} />
                </li>
              ))}
            </ul>
            <div className={styles.navlist_right}>
              <div className={styles.toggle_switch_wrapper}>
                <ThemeToggleBtn />
              </div>
              <div className={styles.lang_select_wrapper}>
                <LanguageSwitcher />
              </div>
              <div
                className={`right_section_container ${styles.right_section_container}`}
              >
                <div
                  className={`logout_btn ${styles.logout_btn}`}
                  onClick={handleClickLogout}
                  data-testid="logout_btn"
                >
                  <span className={styles.logout_txt}>{t("logout")}</span>
                  <img
                    src={logoutIcon}
                    alt="Logout"
                    className={styles.logout_img}
                  />
                </div>
              </div>
            </div>
          </nav>
        )}
      </section>
      <section className={`main_header_wrapper ${styles.main_header_wrapper}`}>
        <div className={styles.leftSection}>
          {isLoginPage ? (
            <div className={`amway_logo ${styles.amway_logo}`}></div>
          ) : (
            <Link to={`/myaccount`}>
              <div className={`amway_logo ${styles.amway_logo}`}></div>
            </Link>
          )}
          {!isLoginPage && (
            <nav className={styles.navbar}>
              <ul className={styles.navlist_left}>
                <li className={styles.nav_item}>
                  <Link to={`/myaccount`}>{t("my_account")}</Link>
                </li>
                {userDetails?.isAccessibleReportBuilderFeature && (
                  <li className={styles.nav_item}>
                    <Link to={`/report`}
                    onClick={(e) => handleLinkClick(e, "/report")}
                    >{t("reports")}</Link>
                  </li>
                )}
                {userDetails?.isAccessibleHATVFeature && (
                  <li className={styles.nav_item}>
                    <Icon
                      name='ai_flower_logo'
                      size={22}
                      color={currentTheme === "light" ? "#1176A9" : "#B2F9FF"}
                      className={styles.ai_flower_logo}
                    />
                    <Link to={`/hatv`}
                      onClick={(e) => handleLinkClick(e, "/hatv")}
                    >{t("hatv")}</Link>
                  </li>
                )}
                {config?.isAPFeatureEnabled &&
                  userDetails?.isAccessibleAPFeature && (
                    <li className={styles.nav_item}>
                      <Link to={`/ap`}>{t("AP_info.ap")}</Link>
                    </li>
                  )}
              </ul>
            </nav>
          )}
        </div>
        {!isLoginPage && (
          <div className={styles.right_section}>
            <div className={styles.search_bar}>
              <SearchBar placeholder={t("search_workspace")} />
            </div>
            <div className={styles.notification_icon}>
              <img className={styles.bell_icon} src={bellIcon} alt="Bell" />
              <div
                className={`notification_count ${styles.notification_count}`}
              >
                5
              </div>{" "}
            </div>
          </div>
        )}
      </section>
    </header>
  );
};

export default Header;
