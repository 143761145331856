import React from "react";
import styles from "./PersonalInfoCard.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { concatenateWithComma } from "../../../../../utils/contactCardUtility";
import { getInitials, formatPhoneNumber, formatUSPostalCode } from "../../../../../utils/common";
import { ICard, RootState } from "../../../../../types/types";
import { getGradientBackground } from "../../../../../utils/getGradientBackground";
import { truncate } from "lodash";
import ButtonMailTo from "../../../../common/ButtonMailTo/ButtonMailTo";
const PersonalInfoCard: React.FC<ICard> = ({
  primary = false,
  name,
  localName,
  phoneIdd,
  phone,
  phoneArea,
  phoneExtension,
  email,
  primaryAddressLine1,
  primaryAddressLine2,
  primaryAddressLine3,
  primaryAddressPostalCode,
  primaryAddressCity,
  primaryAddressState,
  primaryAddressCountry,
  shippingAddressLine1,
  shippingAddressLine2,
  shippingAddressLine3,
  shippingAddressPostalCode,
  shippingAddressCity,
  shippingAddressState,
  shippingAddressCountry,
}) => {
  const { t } = useTranslation();
  const currentTag = useSelector(
    (state: RootState) => state.tag.currentTag || "good",
  );

  const background = getGradientBackground({ tag: currentTag });

  // Concatenate variables with comma separation
  const primaryAddressStreetAgg: string = concatenateWithComma(
    primaryAddressLine1 || "",
    primaryAddressLine2 || "",
    primaryAddressLine3 || "",
  );
  const primaryAddressAgg: string = concatenateWithComma(
    primaryAddressCity || "",
    primaryAddressState || " ",
    primaryAddressCountry || "",
    formatUSPostalCode(primaryAddressPostalCode, primaryAddressCountry)?.toString(),
  );
  const shippingAddressStreetAgg: string = concatenateWithComma(
    shippingAddressLine1 || "",
    shippingAddressLine2 || "",
    shippingAddressLine3 || "",
  );
  const shippingAddressAgg: string = concatenateWithComma(
    shippingAddressCity || "",
    shippingAddressState || " ",
    shippingAddressCountry || "",
    formatUSPostalCode(shippingAddressPostalCode, shippingAddressCountry)?.toString(),
  );

  return (
    <div
      className={`contact_card_container ${styles.card_holder}`}
      data-testid="contact_card_item_testID"
    >
      <div className={`contact_card ${styles.card}`}>
        <div
          className={`card_profile ${styles.card_profile}`}
          data-testid="contact_card_profile_section_testID"
        >
          <div
            className={styles.card_logo_outer}
            style={{ background: background?.initials }}
          >
            <label className={styles.card_logo_inner}>
              {getInitials(name || "")}
            </label>
          </div>
          <div className={`contact_profile_info ${styles.profile_info}`}>
            <label className={`card_text ${styles.name}`}>
              {truncate(name, { length: 28, omission: " ..." })}
            </label>
            {localName && (name !== localName) && <label className={`card_text ${styles.localName}`}>
              {truncate(localName, { length: 28, omission: " ..." })}
            </label>}
            {primary !== undefined && primary !== null && (primary === "true" || primary === true) && (
              <div
                className={`card_tag_container_primary ${styles.tagOuterPrimary}`}
              >
                <label className={`card_tag ${styles.cardTag}`}>
                  {t("contact_info.primary")}
                </label>
              </div>
            )}
            {primary !== undefined && primary !== null && (primary === "false" || primary === false) && (
              <div
                className={`card_tag_container_non_primary ${styles.tagOuterNonPrimary}`}
              >
                <label className={`card_tag ${styles.cardTag}`}>
                  {t("contact_info.co_applicant")}
                </label>
              </div>
            )}
          </div>
        </div>
        <div
          className={`card_info ${styles.card_info}`}
          data-testid="contact_card_personal_Info_testID"
        >
          <div
            className={`${styles.personalInfo}`}
            data-testid="contact_card_phone_number_testID"
          >
            <label className={`card_text_info_header ${styles.infoHeader}`}>
              {t("contact_info.primary_number")}
            </label>
            <label className={`card_text_info ${styles.info}`}>
              {phone ? formatPhoneNumber(phone, phoneIdd, phoneArea, phoneExtension) : "-"}
            </label>
          </div>
          <div
            className={styles.personalInfo}
            data-testid="contact_card_email_testID"
            title={email}
          >
            <label className={`card_text_info_header ${styles.infoHeader}`}>
              {t("contact_info.email_address")}
            </label>
            <label
              className={`card_text_info ${styles.info}`}
              style={{ textDecoration: "underline" }}
            >
              <ButtonMailTo email={email || ""}>{truncate(email, { length: 26, omission: " ..." })}</ButtonMailTo>
            </label>
          </div>
          <div
            className={styles.personalInfo}
            data-testid="contact_card_primary-address_testID"
          >
            <label className={`card_text_info_header ${styles.infoHeader}`}>
              {t("contact_info.primary_address")}
            </label>
            <label className={`card_text_info ${styles.info}`}>
              {truncate(primaryAddressStreetAgg, { length: 33, omission: " ..." })}
            </label>
            {primaryAddressAgg && (
              <label className={`card_text_info ${styles.info}`}>
                {truncate(primaryAddressAgg, { length: 33, omission: " ..." })}
              </label>
            )}
          </div>
          <div
            className={styles.personalInfo}
            data-testid="contact_card_shipping-address_testID"
          >
            <label className={`card_text_info_header ${styles.infoHeader}`}>
              {t("contact_info.shipping_address")}
            </label>
            <label className={`card_text_info ${styles.info}`}>
              {truncate(shippingAddressStreetAgg, { length: 33, omission: " ..." })}
            </label>
            {shippingAddressAgg && (
              <label className={`card_text_info ${styles.info}`}>
                {truncate(shippingAddressAgg, { length: 33, omission: " ..." })}
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoCard;