import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import Icon from "../../common/Icon/Icon";
import styles from "./HATVLandingPage.module.scss";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/store";

interface HATVBotProps {
  onABOListPageChange: (value: boolean) => void;
}

const HATVLandingPage: React.FC<HATVBotProps> = ({ onABOListPageChange }) => {
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
    const { t } = useTranslation();
  
  const viewAboListHandler = () => {
    onABOListPageChange(true);
  };
  return (
    <div className={`${styles.hatv_inner_container}`}>
      <div className={`${styles.hatv_approval_experiance}`}>
        <div className={styles.hatv_approval_header}>
          <div
            className={styles.iconOuter}
            style={{
              backgroundColor:
                currentTheme === "light" ? "#1176A926" : "#282828",
            }}
          >
            <Icon
              name="ai_flower_logo_round"
              size={48}
              color={currentTheme === "light" ? "#1176A9" : "#B2F9FF"}
              className={`${styles.ai_flower_logo}`}
            />
          </div>
          <div className={styles.approval_header_outer}>
            <div className={styles.approval_header}>
              {t("hatv_approval_experience")}
            </div>
          </div>
        </div>
        <div
          className={styles.aboListApprovalContainer}
          style={{
            backgroundColor: currentTheme === "light" ? "#FFFFFF" : "#282828",
          }}
        >
          <span className={styles.aboListApprovalText}>
            {t("show_list")}
          </span>
          <div
            className={styles.rightArrowIcon}
            style={{
              backgroundColor: currentTheme === "light" ? "#FFFFFF" : "#1C1C1E",
              border:
                currentTheme === "light"
                  ? "1px solid #77CBBF"
                  : "1px solid transparent",
            }}
            onClick={viewAboListHandler}
          >
            <Icon
              name="arrow_right"
              size={18}
              color={currentTheme === "light" ? "#1176A9" : "#B2F9FF"}
              className={`${styles.ai_flower_logo}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HATVLandingPage;
