import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toggleTheme } from "../../../redux/slices/themeSlice";
import styles from "./ThemeToggle.module.scss";
import DarkToggleEnabled from "../../../assets/images/dark_toggle_enabled.svg";
import LightToggleEnabled from "../../../assets/images/light_toggle_enabled.svg";
import DarkToggleDisabled from "../../../assets/images/dark_toggle_disabled.svg";
import LightToggleDisabled from "../../../assets/images/light_toggle_disabled.svg";
import { useLocation } from 'react-router-dom';

const ThemeToggle: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dispatch = useAppDispatch();
  const history = useLocation();

  const handleToggleDarkTheme = () => {
    if (currentTheme !== "dark") {
      dispatch(toggleTheme());
    }
  };

  const handleToggleLightTheme = () => {
    if (currentTheme !== "light") {
      dispatch(toggleTheme());
    }
  };

  return (
    <div className={`theme_switch_container ${styles.theme_switch_container}`}>
      <div
        className={`${styles.toggle_switch_btn} ${styles.toggle_switch_left} ${currentTheme === "dark" ? styles.active : ""}`}
        onClick={handleToggleLightTheme}
        data-testid="light_theme_testId"
      >
        <img
          src={
            currentTheme === "light" ? LightToggleEnabled : LightToggleDisabled
          }
          alt="LightTheme"
        />
      </div>
      <div
        className={`${styles.toggle_switch_btn} ${styles.toggle_switch_right} ${currentTheme === "light" ? styles.active : ""}`}
        onClick={handleToggleDarkTheme}
        data-testid="dark_theme_testId"
      >
        <img
          src={currentTheme === "dark" ? DarkToggleEnabled : DarkToggleDisabled}
          alt="DarkTheme"
        />
      </div>
    </div>
  );
};

export default ThemeToggle;
