import { HttpStatusCode } from "axios";
import config from "../../config/config";
import axiosInstance from "../../config/interceptors/axios.interceptor";
import { useState } from "react";

export default function useLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getLoginUrl = () => {
    let authorizeUrl = `${config.apiIssuer}`;
    const redirectUri = `${config.appUrl}/auth/authorize`;
    authorizeUrl += `?response_type=authorization_code&client_id=${config.clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${config.appCode}`;
    return authorizeUrl;
  };

  const onClickLoginBtn = async () => {
    setIsLoading(true);
    setIsError(false);

    await axiosInstance
      .get(`${config.appUrl}/auth/authorize?app=${config.appCode}`)
      .then((res) => {
        if (res) {
          // Scenario: Comeback session. Call token-handler.
          if (res.status === HttpStatusCode.Ok) {
            if (res.data && res.data.statusCode === HttpStatusCode.Found) {
              if (res.data.body) {
                window.location.href = res.data.body;
              }
            }
          } else if (res.status === HttpStatusCode.Found) {
            // Scenario: New session. Authenticated by Okta.
            if (res.headers && res.headers.location) {
              window.location.href = res.headers.location;
            } else {
              // Scenario: New session. Redirect to Okta.
              window.location.href = getLoginUrl();
            }
          }
        } else {
          setIsError(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === HttpStatusCode.Found) {
          window.location.href = getLoginUrl();
          getLoginUrl();
        } else {
          setIsError(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { onClickLoginBtn, isLoading, isError };
}
