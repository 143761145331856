import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import styles from "./APBackground.module.scss";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  useGetAPBackgroundDetailsQuery,
  useSetAPBackgroundDetailsMutation,
} from "../../../../../redux/services/apBackgroundDetailsAPI";
import { formatDate } from "../../../../../utils/dateFormatUtil";
import Button from "../../../../common/Button/Button";
import FormatterBar from "../../../../common/FormatterBar/FormatterBar";
import HTMLtoText from "../../../../common/HTMLToText/HTMLToText";
import ABOBackgroundLoader from "../../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import ErrorInPageScreen from "../../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../../utils/validateAPIResponse";
import { RootState } from "../../../../../redux/store";

type APBackgroundDataType = {
  other?: string;
  audit?: AuditType;
};

type AuditType = {
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
};

function APBackground() {
  const { t } = useTranslation();
  const currentTheme = useAppSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const selectedAP = useAppSelector(
    (state: RootState) => state.apDetails.selectedAP
  );

  const [APBackgroundData, setAPBackgroundData] =
    useState<APBackgroundDataType | null>(null);
  const [updatedAPBackgroundData, setUpdatedAPBackgroundData] =
    useState<APBackgroundDataType | null>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const APBackgroundHeading = "AP_info.loaOrAPBackground";

  const handleDataChange = (editorData: string, section: string) => {
    setUpdatedAPBackgroundData((apBackgroundPreState) => ({
      ...apBackgroundPreState,
      [section]: editorData,
    }));
  };

  const { data, isFetching, isError } = useGetAPBackgroundDetailsQuery(
    { apCode: selectedAP?.code },
    {
      skip: selectedAP?.code === "" || isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setAPBackgroundData(null);
    setUpdatedAPBackgroundData(null);
  };

  useEffect(() => {
    isError && handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        setAPBackgroundData(data?.body ?? {});
        setUpdatedAPBackgroundData(data?.body ?? {});
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const [
    updateAPBackgroundDetails,
    { isLoading: isLoadingAPBackgroundDetails, isError: isErrorInSave },
  ] = useSetAPBackgroundDetailsMutation();

  useEffect(() => {
    isErrorInSave && handleAPIError();
  }, [isErrorInSave]);

  const handleClickEdit = () => {
    setIsEditable(true);
  };

  const handleClickCancel = () => {
    setIsEditable(false);
    setUpdatedAPBackgroundData(APBackgroundData);
  };

  const handleClickSave = async () => {
    const body = {
      other: updatedAPBackgroundData?.other ?? "",
    };

    const response: any = await updateAPBackgroundDetails({
      apCode: selectedAP?.code,
      payload: body,
    });

    if (isValidResponse(response?.data?.statusCode)) {
      setIsEditable(false);
      setAPBackgroundData(response?.data?.body);
      setUpdatedAPBackgroundData(response?.data?.body);
    } else {
      handleAPIError();
    }
  };

  const isEmptyLastModifiedDate = () => {
    return isEmpty(APBackgroundData?.audit?.updatedDate);
  };

  return (
    <div
      className={`abo_bg_container ${styles.ap_background_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {(isFetching || isLoadingAPBackgroundDetails) && (
        <ABOBackgroundLoader num={5} heading={t(APBackgroundHeading)} />
      )}
      {!(isFetching || isLoadingAPBackgroundDetails) && isAPIError && (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="abo_bg_heading_testID"
            >
              {t(APBackgroundHeading)}
            </span>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      )}
      {!(isFetching || isLoadingAPBackgroundDetails) && !isAPIError && (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="abo_bg_heading_testID"
            >
              {t(APBackgroundHeading)}
            </span>
            <span className={styles.right_section}>
              {!isEditable && (
                <span
                  className={styles.edit_section}
                  onClick={handleClickEdit}
                  data-testid="edit_testID"
                >
                  <span
                    className={
                      currentTheme === "light"
                        ? styles.edit_icon_light
                        : styles.edit_icon_dark
                    }
                  ></span>
                  <span>{t("AP_info.edit")}</span>
                </span>
              )}
            </span>
          </div>
          <div className={`grey_text ${styles.sub_heading}`}>
            {t("AP_info.backgroundSubTitle")}
          </div>
          {
            <div className={`grey_text ${styles.last_modified}`}>
              {!isEmptyLastModifiedDate() && t("AP_info.last_modified")}{" "}
              {!isEmptyLastModifiedDate() &&
                formatDate(APBackgroundData?.audit?.updatedDate)}
            </div>
          }
          <div className={styles.details_wrapper}>
            <div className={styles.item_wrapper}>
              {isEditable ? (
                <FormatterBar
                  id="other"
                  testId="other_testID"
                  label={t("AP_info.background")}
                  editorData={APBackgroundData?.other || ""}
                  handleDataChange={handleDataChange}
                  section={"other"}
                  isContentEditable={false}
                  maxCharacter={2000}
                />
              ) : (
                <>
                  <div
                    className={styles.item_name}
                    data-testid="abo_bg_item_testID"
                  >
                    {t("AP_info.background")}
                  </div>
                  <div className={`grey_text ${styles.item_value}`}>
                    <HTMLtoText
                      htmlContent={APBackgroundData?.other || ""}
                      alternateMessage={t("AP_info.no_info_available")}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {isEditable && (
            <div className={styles.btn_wrapper}>
              <Button
                id="cancelButton"
                onClick={handleClickCancel}
                label={t("AP_info.btnCancel")}
                style="unfilled"
              />
              <Button
                id="saveButton"
                onClick={handleClickSave}
                label={t("AP_info.btnSave")}
                style="filled"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default APBackground;
