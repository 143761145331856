import React from "react";
import Button from "../Button/Button";
import styles from "./DeleteConfirmationPopUp.module.scss";


interface DeleteConfirmationProps {
    id?: string;
    onCancel: () => void;
    onDelete: () => void;
    titleLabel: string;
    msgText: string;
    buttonLabelOne: string;
    buttonLabelTwo: string;
    customStyle?: React.CSSProperties;
  }



const DeleteConfirmationPopUp:React.FC<DeleteConfirmationProps> = ({   
    id,
    onCancel,
    onDelete,
    titleLabel,
    msgText,
    buttonLabelOne,
    buttonLabelTwo,
    customStyle}) => {

    return <div className={`${styles.confirmation_popup_container}`} data-testid="delete_document_confirmation_testID">
                <div className={`${styles.title_container}`}>{titleLabel}</div>
                <div className={`${styles.message_container}`}>{msgText}</div>
                <div className={`${styles.actionButton_container}`}>
                    <Button
                        id="noCancelButton"
                        onClick={onCancel}
                        label={buttonLabelOne}
                        style="unfilled"
                        customStyle={customStyle}
                    />
                    <Button
                        id="yesCancelButton"
                        onClick={onDelete}
                        label={buttonLabelTwo}
                        style="filled"
                        customStyle={customStyle}
                    />
                </div>

           </div>
}

export default DeleteConfirmationPopUp;