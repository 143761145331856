import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery/axiosBaseQuery";
import config from "../../config/config";

export const reportBuilderABOListAPI = createApi({
  reducerPath: "reportBuilderABOListAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: config?.appUrl,
  }),
  keepUnusedDataFor: 900,
  tagTypes: ["rbabolist"],
  endpoints: (builder) => ({
    getReportBulderAboList: builder.query<
      any,
      { reportType: string; payload: any }
    >({
      query: ({ reportType, payload }) => ({
        url: `${reportType === "gar_tracking_report" ? `/am/report/abos/diamond` : `/am/report/abos`}`,
        method: "POST",
        data: payload,
      }),
      providesTags: ["rbabolist"],
    }),
    getReportSummary: builder.mutation<ReportSummaryResponse, ReportRequest>({
      query: (payload) => ({
        url: "/am/report/generate",
        method: "POST",
        data: payload,
      }),
    }),
  }),
});

export const {
  useLazyGetReportBulderAboListQuery,
  useGetReportSummaryMutation,
} = reportBuilderABOListAPI;
