import React, { useRef, useEffect, ReactNode } from "react";
import styles from "./SlideoutDrawer.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import closeIconLight from "../../../assets/images/LightTheme/close_icon_light.svg";
import closeIconDark from "../../../assets/images/DarkTheme/close_icon_dark.svg";
import { useTranslation } from "react-i18next";
interface DrawerProps {
  showDrawer: boolean;
  onClose?: () => void;
  children?: ReactNode;
  position?: "left" | "right"; // Optional: support left or right positioning
  customStyle?: React.CSSProperties;
}

const SlideoutDrawer: React.FC<DrawerProps> = ({
  showDrawer,
  onClose,
  children,
  position = "right",
  customStyle,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const closeIcon = currentTheme === "dark" ? closeIconDark : closeIconLight;
  const { t } = useTranslation();

  const handleKeyUp: EventListener = (e) => {
    if (
      e instanceof KeyboardEvent &&
      e.key === "Escape" &&
      showDrawer &&
      onClose
    ) {
      onClose();
    }
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    if (drawerRef.current === e.target && onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (showDrawer) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showDrawer]);

  return (
    <>
      {showDrawer && (
        <div className={`drawer_container ${styles.drawer_container}`}>
          <div
            className={`drawer_backdrop ${styles.drawer_backdrop}`}
            onClick={handleClickOutside}
            ref={drawerRef}
          ></div>
          <div className={`drawer ${styles.drawer}`}
            style={customStyle}
          >
            <div className={`drawer_header ${styles.drawer_header}`}>
              <div className={`drawer_heading ${styles.drawer_heading}`}>
                {t("drawer_heading")}
              </div>
              <div className={styles.close_icon} onClick={onClose}>
                <img src={closeIcon} alt="close" width="10px" height="11px" />
              </div>
            </div>
            <div className={`drawer_content ${styles.drawer_content}`}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default SlideoutDrawer;
