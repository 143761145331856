import React, { useEffect, useState } from "react";
import styles from "./APNotesSearchBar.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../types/types";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Icon from "../../../../../common/Icon/Icon";
import { DIGIT_SIX_HUNDRED, DIGIT_TWO } from "../../../../../../constants/labelConstants";

interface SearchBarProps {
  placeholder: string;
  searchAPNotes: (args: string) => {}
}

const APNotesSearchBar: React.FC<SearchBarProps> = ({ placeholder, searchAPNotes }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const currentTheme = useSelector((state: RootState) => state.theme.currentTheme);

  const searchDebounceAPNotes =
    debounce(async (term) => {
      if (term?.trim()?.length > DIGIT_TWO || term?.trim()?.length === 0) {
        searchAPNotes(term);
      }
    }, DIGIT_SIX_HUNDRED);

  const handleSearchABO = async (term: string) => {
    setSearchTerm(term);
    searchDebounceAPNotes(term);
  };
  const handleSearchInput = async () => {
    searchDebounceAPNotes(searchTerm);
	};

  useEffect(() => {
    setSearchTerm("");
  }, [navigate]);

  return (
    <div className={`search_bar_container ${styles.search_bar_container}`}>
      <Icon
        name="search"
        size={24}
        color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
        onClick={handleSearchInput}
        className={styles.search_icon}
      />
      <input
        className={`search_input ${styles.search_input}`}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(event) => handleSearchABO(event.target.value)}
      />
    </div>
  );
};

export default APNotesSearchBar;
