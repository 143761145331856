import React from "react";
import styles from "./ErrorHandling.module.scss";
import { ReactComponent as ConnectivityIssueLightImg } from "../../assets/images/LightTheme/no_connection_light.svg";
import { ReactComponent as ConnectivityIssueDarkImg } from "../../assets/images/DarkTheme/no_connection_dark.svg";
import { ReactComponent as SessionExpiryLightImg } from "../../assets/images/LightTheme/session_expiry_light.svg";
import { ReactComponent as SessionExpiryDarkImg } from "../../assets/images/DarkTheme/session_expiry_dark.svg";
import { ReactComponent as ServerDownLightImg } from "../../assets/images/LightTheme/server_down_light.svg";
import { ReactComponent as ServerDownDarkImg } from "../../assets/images/DarkTheme/server_down_dark.svg";
import Button from "../common/Button/Button";
import { useAppSelector } from "../../redux/hooks";
import useLogin from "../CustomHooks/useLogin";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";

type ComponentProps = {
  error: any;
};
const ConnectionIssueComponent = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const NoConnectionImage =
    currentTheme === "light"
      ? ConnectivityIssueLightImg
      : ConnectivityIssueDarkImg;

  return (
    <div className={`error_container ${styles.error_container}`}>
      <div>
        <NoConnectionImage />
      </div>
      <div className={styles.error_message_section}>
        <div className={styles.error_message_1}>
          {t("somethingWentWrongMsg")}
        </div>
        <div className={styles.error_message_2}>
          {t("checkYourConnectionMsg")}
        </div>
      </div>
      <div>
        <Button
          id="refreshButton"
          onClick={() => window.history.back()}
          label={t("refresh")}
          style="unfilled"
        />
      </div>
    </div>
  );
};

const GenericErrorComponent = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const NoConnectionImage =
    currentTheme === "light"
      ? ConnectivityIssueLightImg
      : ConnectivityIssueDarkImg;

  return (
    <div
      className={`error_container ${styles.error_container} ${styles.align_center}`}
    >
      <div>
        <NoConnectionImage />
      </div>
      <div className={styles.error_message_section}>
        <div className={styles.error_message_1}>
          {t("somethingWentWrongMsg")}
        </div>
      </div>
      <div>
        <Button
          id="tryAgainBtn"
          onClick={() => window.location.reload()}
          label={t("try_again")}
          style="unfilled"
        />
      </div>
    </div>
  );
};

const AuthenticationIssueComponent = () => {
  const { t } = useTranslation();
  const { onClickLoginBtn, isLoading, isError } = useLogin();
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const SessionExpiryImage =
    currentTheme === "light" ? SessionExpiryLightImg : SessionExpiryDarkImg;

  return (
    <div className={`error_container ${styles.error_container}`}>
      <div>
        <SessionExpiryImage />
      </div>
      <div className={styles.error_message_section}>
        <div className={styles.error_message_1}>{t("sessionExpiredMsg")}</div>
        <div className={styles.error_message_2}>{t("signInAgain")}</div>
      </div>
      <div>
        <Button
          id="loginButton"
          onClick={onClickLoginBtn}
          label={t("logIn")}
          style="unfilled"
          isLoading={isLoading}
        />
      </div>
      {isError && (
        <div className={`error_message ${styles.error_msg}`}>
          {t("something_went_wrong")}
        </div>
      )}
    </div>
  );
};
const ServerDownComponent = () => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const ServerDownImage =
    currentTheme === "light" ? ServerDownLightImg : ServerDownDarkImg;

  return (
    <div className={`error_container ${styles.error_container}`}>
      <div>
        <ServerDownImage />
      </div>
      <div className={styles.error_message_section}>
        <div className={styles.error_message_1}>{t("downForMaintenance")}</div>
        <div className={styles.error_message_2}>{t("checkBackSoon")}</div>
      </div>
    </div>
  );
};

const ErrorMappingObj = {
  connectivityIssue: <ConnectionIssueComponent />,
  authenticationIssue: <AuthenticationIssueComponent />,
  serviceIsDownIssue: <ServerDownComponent />,
  genericError: <GenericErrorComponent />,
};

const ErrorHandling: React.FC<ComponentProps> = ({ error }) => {
  //   const value = (ErrorMappingObj as any)[error];
  type ErrorCode = keyof typeof ErrorMappingObj;

  // Assuming 'error' is a valid ErrorCode
  const error1: ErrorCode = error;

  const value = ErrorMappingObj[error1];
  return <>{value}</>;
};

export default ErrorHandling;
