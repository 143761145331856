import React from 'react';
import styles from './ReportCard.module.scss';
import { useTranslation } from 'react-i18next';
import AIInsight from '../../common/AGCDetailsTile/InsightAI/InsightAI';
import Icon from '../../common/Icon/Icon';
import { useAppSelector } from '../../../redux/hooks';
import downloadLogoLight from '../../../assets/images/LightTheme/report_download_icon.svg';
import downloadLogoDark from '../../../assets/images/DarkTheme/report_download_icon.svg';
import { ReportName } from '../../../constants/dataConstants';


interface ReportCardProps {
  onClickCreateNewReport: () => void;
  reportSummary: ReportData[];
  isReportTypeSelected: string;
  selectedABOs: SelectedItem[];
}

const ReportCard: React.FC<ReportCardProps> = ({
  onClickCreateNewReport = () => { },
  reportSummary,
  isReportTypeSelected,
  selectedABOs,
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const download_icon = currentTheme === "light" ? downloadLogoLight : downloadLogoDark;

  const downloadFile = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noopener noreferrer";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <div className={`card_wrapper ${styles.card_wrapper}`}>
      <div className={styles.header_wrapper}>
        <div className={styles.header}>Report Builder</div>
        <div className={`create_report_button ${styles.create_report_button}`} onClick={onClickCreateNewReport}>
          <div className={`create_text ${styles.create_text}`}>
            <Icon
              name='plus_white'
              size={14}
              color={currentTheme === "light" ? "#2c2c2c" : "#D8D8D9"}
              className={styles.plus_icon}
            />
            <span>{t("create_report")} </span>
          </div>
        </div>
      </div>

      {Array.isArray(reportSummary) && reportSummary?.map((report, index) => (
        <div className={`report_card ${styles.report_card}`} key={index}>
          <div className={`report_header ${styles.report_header}`}>
            <div className={styles.abo_details}>
              <div className={`report_title_wrapper ${styles.report_title_wrapper}`}>
                <div className={`report_title ${styles.report_title}`}>
                  {selectedABOs?.[index]?.name} <span className={styles.report_id}>{"#"}{report.aboNumber}</span>
                </div>
              </div>
              <div className={`report_details ${styles.report_details}`}>
                <span className={`first_supporting_text ${styles.first_supporting_text}`}>{ReportName[isReportTypeSelected]}</span>
                <span className={`second_supporting_text ${styles.second_supporting_text}`}>Word Doc — 1.2 MB</span>
              </div>
            </div>

            <div className={styles.report_actions}>
              <div className={`download_button ${styles.download_button}`}
                onClick={() => downloadFile(report.gcsLink)}
              >
                <img
                  src={download_icon}
                  alt="Follow up arrow"
                  className={styles.arrow_image}
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          </div>
          <div className={styles.ai_summary}>
            <AIInsight
              content={report.aiSummary}
              showDropdown={false}
              isDropdownEnabled={true}
              flowerIconSize={24}
              infoIconSize={12}
              title={"ai_summary"}
              titleSize={14}
              className={styles.report_insight}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportCard;
