import React from "react";
import styles from "./PersonalInformation.module.scss";
import ContactCardInformation from "./ContactCardInformation/ContactCardInformation";
import ABOBackground from "./ABOBackground/ABOBackground";
import SuccessionPlan from "./SuccessionPlan/SuccessionPlan";
import useScroll from "../../../redux/slices/useScrollSlice";

interface PersonalInformationProps {
  activeLink: string;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
  isSourceAP: boolean;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  activeLink,
  refMap,
  onSectionChange,
  isSourceAP,
}) => {
  const { handleClick } = useScroll(refMap, onSectionChange);

  return (
    <div
      className={styles.personal_info_wrapper}
      data-testid="personal_information_section_testID"
    >
      <div className={`content_inner ${styles.inner_wrapper}`}>
        <div
          className={styles.section_wrapper}
          ref={refMap["contact_information"]}
        >
          <ContactCardInformation />
        </div>
        <div className={styles.section_wrapper} ref={refMap["IBO_background"]}>
          <ABOBackground />
        </div>
        <div className={styles.section_wrapper} ref={refMap["succession_plan"]}>
          <SuccessionPlan />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
