import axios, { HttpStatusCode } from "axios";
import config from "../config";
import { QUERY_STATUS } from "../../constants/dataConstants";
import authService from "../auth.service";
import isEmpty from "lodash/isEmpty";
import {v4 as generateUUID} from "uuid";

const axiosInstance = axios.create({
  baseURL: `${config.appUrl}`,
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
  timeout: 180000,
});

let tokenRefreshInProgress = false;

export const setupInterceptors = (history: any) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("auth-accessToken");
    const uniqueUUID = generateUUID();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.withCredentials = true;
    config.headers['X-TRACE-REQUESTID'] = uniqueUUID;

    if (config.url && authService.isAccessTokenApiUrl(config.url)) {
      if (!tokenRefreshInProgress) {
        if (authService.isAccessTokenNeedsRefresh()) {
          refreshAccessToken();
        } else if (authService.isSessionTokenNeedsRefresh()) {
          refreshSessionToken();
        } else if (isEmpty(token)) {
          authService.clearAuth();
          window.location.href = "/login";
        } else if (!authService.isAuthenticated()) {
          window.location.href = `/error?errorType=${"authenticationIssue"}`;
        }
      } else {
        console.log(
          `outside tokenRefreshInProgress: ${tokenRefreshInProgress}`
        );
      }
    }
    return config;
  }, null);

  axiosInstance.interceptors.response.use(
    (response) => {
      const token = localStorage.getItem("auth-accessToken");
      if (isEmpty(token)) {
        window.location.href = "/login";
      } else {
        if (
          response &&
          response.data &&
          response.data.statusCode === HttpStatusCode.Unauthorized
        ) {
          window.location.href = `/error?errorType=${"authenticationIssue"}`;
        }
        if (
          response &&
          response.data &&
          response.data.head &&
          response.data.head.authentication
        ) {
          const auth = response.data.head.authentication;
          if (
            auth.loginSessionStatus !== QUERY_STATUS.ACTIVE &&
            auth.userSessionStatus !== QUERY_STATUS.ACTIVE
          ) {
            window.location.href = `/error?errorType=${"authenticationIssue"}`;
          }
        }
        if (
          response &&
          response.data &&
          response.data.statusCode === HttpStatusCode.ServiceUnavailable
        ) {
          window.location.href = `/error?errorType=${"serviceIsDownIssue"}`;
        }
        if (!response?.config?.url?.includes("/auth")) {
          return response;
        }
      }
      return response;
    },
    (error) => {
      const token = localStorage.getItem("auth-accessToken");
      if (isEmpty(token)) {
        window.location.href = "/login";
      } else {
        if (error && error?.response) {
          if (error.response.status === HttpStatusCode.Unauthorized) {
            window.location.href = `/error?errorType=${"authenticationIssue"}`;
          } else if (
            error.response.status === HttpStatusCode.ServiceUnavailable
          ) {
            window.location.href = `/error?errorType=${"serviceIsDownIssue"}`;
          }
        }
      }
    }
  );

  const refreshAccessToken = () => {
    tokenRefreshInProgress = true;
    const url = `${config.appUrl}/auth/refresh/token`;
    try {
      axiosInstance.get(url).then((res) => {
        if (
          res &&
          res.data &&
          res.data.statusCode === HttpStatusCode.Ok &&
          res.data.body
        ) {
          tokenRefreshInProgress = false;
          localStorage.setItem("auth-accessToken", res.data.body);
        }
      });
    } catch (err) {
      console.log(`Refresh access token error: ${err}`);
      authService.clearAuth();
      tokenRefreshInProgress = false;
    }
  };

  const refreshSessionToken = () => {
    tokenRefreshInProgress = true;
    const url = `${config.appUrl}/auth/refresh/session`;
    try {
      axiosInstance.get(url).then((res) => {
        if (
          res &&
          res.data &&
          res.data.statusCode === HttpStatusCode.Ok &&
          res.data.body
        ) {
          tokenRefreshInProgress = false;
          localStorage.setItem("auth-accessToken", res.data.body);
        }
      });
    } catch (err) {
      console.log(`Refresh session token error: ${err}`);
      authService.clearAuth();
      tokenRefreshInProgress = false;
    }
  };
};

export default axiosInstance;
