import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CustomizeView.module.scss";
import Checkbox from "../CustomCheckbox/CustomCheckbox";
import Button from "../Button/Button";
import SavedViewCard from "../SavedViewCard/SavedViewCard";
import { useDispatch } from "react-redux";
import {
  useCreateViewsMutation,
  useDeleteViewMutation,
  useLazyGetViewDetailsQuery,
  useUpdateViewsMutation,
} from "../../../redux/services/customizeViewAPI";
import { awardGroupCodeMapping } from "../../../constants/dataConstants";
import { updateViewId } from "../../../redux/slices/viewSlice";
import { useAppSelector } from "../../../redux/hooks";
import { withUtag } from "../../../utils/analytics";

interface CustomizeViewModalProps {
  onClose: () => void;
  savedViews: ViewListDataType[];
  setViewDetails: any;
}

const CustomizeViewModal: React.FC<CustomizeViewModalProps> = ({
  onClose,
  savedViews,
  setViewDetails,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedAwardCode, setSelectedAwardCode] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [apiColumsList, setAPIColumnList] = useState<string[]>([]);
  const [selectedColumnList, setSelectedColumnList] = useState<
    ColumnDataType[]
  >([]);
  const [setDefaultView, setSetDefaultView] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [editViewId, setEditViewId] = useState(0);
  const [value, setValue] = useState("");
  const [recentDefaultView, setRecentDefaultView] =
    useState<ViewListDataType>();
  const dispatch = useDispatch();
  const viewId = useAppSelector((state: any) => state.viewIdDetails.viewId);

  const [createView, { isLoading: isLoadingCreateView }] =
    useCreateViewsMutation();

  const [updateView, { isLoading: isLoadingUpdateView }] =
    useUpdateViewsMutation();

  const [viewDetailsAPI] =
    useLazyGetViewDetailsQuery();

  const customViewConfigKPIList = useAppSelector((state: any) => state?.customViewConfigKPIList?.customViewConfigKPIList);
  useEffect(() => {
    const kpiCodes = customViewConfigKPIList?.filter((item: any) => item?.type === "my-abo")?.map((kpiData: any) => kpiData?.code);

    // TODO: dynamically create the object similar to aboColumnObjectMapping at below code and set in local state varible for more dynamic col'm list
    // const kpiCodeAndValueObject = customViewConfigKPIList.reduce((acc:any, item:any) => {
    //   acc[item.code] = { code: item.code, value: item.value.toString() };
    //   return acc;
    // }, {});

    setAPIColumnList(kpiCodes);
  }, [customViewConfigKPIList]);

  const aboColumnObjectMapping: {
    [key: string]: { code: string; value: string };
  } = {
    tracking_award: {
      code: "tracking-award",
      value: t("tracking_award"),
    },
    monthly_revenue: {
      code: "monthly-revenue",
      value: t("monthly_revenue"),
    },
    monthly_contributors: {
      code: "monthly-contributor",
      value: t("monthly_contributors"),
    },
    annual_revenue: {
      code: "annual-revenue",
      value: t("annual_revenue"),
    },
    annual_contributor_count: {
      code: "annual-contributor",
      value: t("annual_contributor_count"),
    },
    fgi_fq: {
      code: "fgi-fq",
      value: t("Account_table_headers.FGI_PQs"),
    },
    pggi_pq: {
      code: "pggi-pq",
      value: t("Account_table_headers.PGI_PQs"),
    },
    gpv_current: {
      code: "gpv-current",
      value: t("Account_table_headers.This_Month_GPV"),
    },
    gpv_last: {
      code: "gpv-last",
      value: t("Account_table_headers.Last_Month_GPV"),
    },
    rpv_current: {
      code: "rpv-current",
      value: t("Account_table_headers.This_Month_RPV"),
    },
    rpv_last: {
      code: "rpv-last",
      value: t("Account_table_headers.Last_Month_RPV"),
    },
    current_month_ppv: {
      code: "ppv",
      value: t("Account_table_headers.This_Month_PPV"),
    },
    // qColumn_ql: {
    //   code: "ql",
    //   value:t("Account_table_headers.Q_Legs"),
    // },
    // qColumn_qm: {
    //   code: "qm",
    //   value:t("Account_table_headers.Q_Months"),
    // },
    total_count: {
      code: "totalSPQualifiedMonthsLos",
      value: t("Account_table_headers.Total_Qs"),
    },
    ttci_ttca: {
      code: "ttci-ttca",
      value: t("Account_table_headers.ttci_ttca"),
    },

    //  loa
    // "silver-qm" <==> totalSPQualifiedMonthsLos TODO: the code should come 
    // "tracking-dbr"
    // "tracking-fdbr"

  };

  const [deleteView, { isLoading: isLoadingDeleteView }] =
    useDeleteViewMutation();

  useEffect(() => {
    if (savedViews && savedViews?.length >= 1) {
      const views = savedViews?.filter((view) => view._default);
      const defaultViews = views.sort(
        (a, b) =>
          new Date(b.audit.updatedDate).getTime() -
          new Date(a.audit.updatedDate).getTime()
      );
      // Get the most recent default view

      setRecentDefaultView(defaultViews[0]);
    }
  }, [savedViews]);

  const [editIndex, setEditIndex] = useState<number | null>(null);

  const accounts = [
    "all_accounts",
    "diamond_above_default",
    "founders_platinum_emerald",
    "sp_gp_platinum",
  ];

  const columns = [
    "tracking_award",
    "monthly_revenue",
    "monthly_contributors",
    "annual_revenue",
    "annual_contributor_count",
    "fgi_fq",
    "pggi_pq",
    "gpv_current",
    "gpv_last",
    "rpv_current",
    "rpv_last",
    "current_month_ppv",
    // "qColumn_ql",
    // "qColumn_qm",
    "total_count",
    "ttci_ttca"
  ];

  function validateViewName(viewName: any) {
    const trimmedInput = viewName.trim();
    const regex = /^[a-zA-Z0-9 ]+$/;
    return regex.test(trimmedInput);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || validateViewName(value)) {
      setValue(value);
    } else {
    }
  };
  const handleAccountSelection = (account: string) => {
    if (account === "all_accounts") {
      if (selectedAccounts.includes(account)) {
        setSelectedAccounts([]);
        setSelectedAwardCode([]);
      } else {
        setSelectedAccounts(accounts);
        setSelectedAwardCode([awardGroupCodeMapping["all_accounts"]]);
      }
    } else {
      setSelectedAccounts((prev: string[]) => {
        const isSelected = prev.includes(account);
        let newAccounts: string[];

        if (isSelected) {
          newAccounts = prev.filter(item => item !== account);

          if (newAccounts.includes("all_accounts")) {
            newAccounts = newAccounts.filter(item => item !== "all_accounts");
          }
        } else {
          newAccounts = [...prev, account];
        }

        const allIndividualAccountsSelected = newAccounts?.length === accounts?.length - 1;

        if (allIndividualAccountsSelected) {
          setSelectedAwardCode([awardGroupCodeMapping["all_accounts"]]);
        } else {
          setSelectedAwardCode((prev: string[]) => {
            const awardCodesToInclude = newAccounts.map(acc => awardGroupCodeMapping[acc]);
            return Array.from(new Set(awardCodesToInclude));
          });
        }

        return allIndividualAccountsSelected ? [...newAccounts, "all_accounts"] : newAccounts;
      });
    }
  };


  const handleColumnSelection = (column: string) => {
    setSelectedColumns((prev) =>
      prev.includes(column)
        ? prev.filter((item) => item !== column)
        : [...prev, column]
    );

    setSelectedColumnList((prev) => {
      const columnObject = aboColumnObjectMapping[column]?.value;
      return prev.some((item) => item?.value === columnObject)
        ? prev.filter((item) => item?.value !== columnObject)
        : [...prev, aboColumnObjectMapping[column]];
    });
  };

  const codes: string[] = selectedColumnList?.map((item) => item.code);
  const selectedColumnCodes = `(${codes?.map((item) => `"${item}"`).join(", ")})`;
  const selectedAccountData = `(${selectedAccounts?.map((item) => `"${item}"`).join(", ")})`;

  const handleSetDefaultView = (checked: boolean) => {
    setSetDefaultView(checked);
  };

  const canProceed = () => {
    if (activeStep === 0) {
      return selectedAwardCode?.length > 0;
    } else if (activeStep === 1) {
      return selectedColumnList?.length >= 3 && selectedColumnList?.length <= 8;
    } else if (activeStep === 2) {
      return value?.length > 0;
    }
    return false;
  };

  useEffect(() => {
    setIsChecked(canProceed());
  }, [selectedAwardCode, selectedColumnList, activeStep, value]);

  const handleContinue = () => {
    if (canProceed()) {
      if (activeStep === 2) {
        handleSaveView(isUpdate ? updateView : createView);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  };
  const handleApplyColumn = () => {
    onClose();
    const applyColumnData = {
      accounts_selected: selectedAccountData,
      columns_selected: selectedColumnCodes,
    };

    withUtag().then((utag) => utag.link({
      event_name: "customize_view_apply_notsave",
      accounts_selected: selectedAccountData,
      columns_selected: selectedColumnCodes,
    }));

    setViewDetails({
      awardGroupCodes: selectedAwardCode,
      kpis: selectedColumnList,
    });

    dispatch(
      updateViewId({
        viewIdDetails: {
          defaultViewId: null,
          selectedViewId: 0,
        },
      })
    );
  };

  const handleDelete = async (viewId: number) => {
    const response: any = await deleteView({
      viewId: viewId,
    });

    if (response?.data?.statusCode !== 200) {
      console.error(`Deletion failed for the view : ${viewId}`);
    }
    if (response?.data?.statusCode === 200) {
      dispatch(
        updateViewId({
          viewIdDetails: {
            defaultViewId: null,
            selectedViewId: 0,
          },
        })
      );
    }
  };

  const getViewDetails = async (viewId: number) => {

    const response = await viewDetailsAPI({ viewId });
    if (response?.data?.statusCode === 200) {
      return response?.data?.body?.viewSetting
    }
  }


  const handleEdit = async (view: any) => {
    setIsUpdate(true);
    setEditViewId(view?.viewId);
    const viewSettings = await getViewDetails(view?.viewId);
    setValue(view?.name);
    setActiveStep(0);
    setActiveTab(0);
    setSelectedAwardCode(viewSettings?.awardGroupCodes || []);
    setSelectedColumnList(viewSettings?.kpis || []);
    setSetDefaultView(view?._default);
  };

  const handleSaveView = async (saveViewAPI: any) => {
    onClose();
    const createViewPayload = {
      type: "my-abo",
      name: value,
      _default: setDefaultView,
      viewSetting: {
        awardGroupCodes: selectedAwardCode,
        kpis: selectedColumnList,
      },
      description: "",
    };

    const updateViewPayload = {
      ...createViewPayload,
      viewId: editViewId,
    };

    // if (savedViews && savedViews.length == 6 && !isUpdate) {
    //   console.log("View creation limit exceeded");
    // } else {
    const response: any = await saveViewAPI({
      payload: isUpdate ? updateViewPayload : createViewPayload,
    });

    if (response?.data?.statusCode === 200) {
      const saveViewData = {
        accounts_selected: selectedAccountData,
        columns_selected: selectedColumnCodes,
      };

      withUtag().then((utag) => utag.link({
        event_name: "customize_view_apply_save",
        accounts_selected: selectedAccountData,
        columns_selected: selectedColumnCodes,
      }));

      dispatch(
        updateViewId({
          ...viewId,
          viewIdDetails: {
            defaultViewId: null,
            selectedViewId: response?.data?.body?.viewId,
          },
        })
      );
      if (isUpdate) {
        setViewDetails({
          awardGroupCodes: selectedAwardCode,
          kpis: selectedColumnList,
        });
      }
    } else if (response?.data?.statusCode === 400) {
      console.log("Not allow to have view with duplicate name");
    }
    // }
  };

  const resetFormFields = () => {
    setSelectedAccounts([]);
    setSelectedAwardCode([]);
    setSelectedColumns([]);
    setSelectedColumnList([]);
    setSetDefaultView(false);
    setValue("");
    setEditIndex(null);
  };

  const handleClickBackOrCancle = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      setActiveStep((prev) => Math.max(prev - 1, 0));
    }
  };

  return (
    <div className={`customize_view_modal ${styles.customize_view_modal}`}>
      <div className={styles.modal_title}>{t("customize_view")}</div>
      <div className={`tabs_wrapper ${styles.tabs_wrapper}`}>
        <div
          className={`tablink ${styles.tablink} ${activeTab === 0 ? `active_tab ${styles.active_tab}` : ""}`}
          onClick={() => {
            setActiveTab(0);
            setActiveStep(activeStep);
          }}
        >
          {t("create_new_view")}
        </div>
        <div
          className={`tablink ${styles.tablink} ${activeTab === 1 ? `active_tab ${styles.active_tab}` : ""}`}
          onClick={() => setActiveTab(1)}
        >
          {t("saved_views")}
        </div>
      </div>
      {activeTab === 0 ? (
        <div className={styles.steps_wrapper}>
          {activeStep === 0 ? (
            <div className={styles.steps_inner_wrapper}>
              <div
                className={`step_title ${`step_title ${styles.step_title}`}`}
              >
                {t("step1")}
              </div>
              {accounts?.map((account) => (
                <div className={styles.checkbox_wrapper} key={account}>
                  <Checkbox
                    checked={
                      account === "all_accounts"
                        ? selectedAccounts?.length === accounts?.length
                        : selectedAccounts?.includes(account)
                    }
                    label={t(account)}
                    checkboxHandler={() => handleAccountSelection(account)}
                    isDisabled={false}
                  />
                </div>
              ))}
            </div>
          ) : activeStep === 1 ? (
            <div className={styles.steps_inner_wrapper}>
              <div className={`step_title ${styles.step_title}`}>
                {selectedColumnList?.length >= 3 ? t("step2Title") : t("step2")}
              </div>
              {columns?.map((column) => (
                apiColumsList.includes(aboColumnObjectMapping[column].code) &&
                <div className={styles.checkbox_wrapper} key={column}>
                  <Checkbox
                    checked={selectedColumnList.some(
                      (item) =>
                        item.code === aboColumnObjectMapping[column].code
                    )}
                    label={t(aboColumnObjectMapping[column].value)}
                    checkboxHandler={() => handleColumnSelection(column)}
                    isDisabled={false}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={`${styles.steps_wrapper} ${styles.third_step}`}>
              <div className={`step_title ${styles.step_title}`}>
                {t("step3")}
              </div>
              <div
                className={`${styles.input_wrapper} ${isFocused || value ? styles.focused : ""}`}
              >
                <input
                  type="text"
                  className={`input_field ${styles.input_field} ${value ? `input_with_text ${styles.input_with_text}` : null}`}
                  value={value}
                  onChange={handleChange}
                  placeholder={t("name_your_saved_view")}
                  maxLength={50}
                />
                {value && (
                  <label className={`placeholder ${styles.placeholder}`}>
                    {t("name_your_saved_view")}
                  </label>
                )}
              </div>
              <div className={styles.checkbox_wrapper}>
                <Checkbox
                  checked={setDefaultView}
                  label={t("set_as_default_view")}
                  checkboxHandler={handleSetDefaultView}
                  isDisabled={false}
                />
              </div>
            </div>
          )}
          <div
            className={`bottom_button_wrapper ${styles.bottom_button_wrapper}`}
          >
            <div className={styles.bottom_button}>
              <Button
                id="backButton"
                onClick={handleClickBackOrCancle}
                label={activeStep === 0 ? t("btnCancel") : t("back")}
                style="unfilled"
              />
              <Button
                id="saveButton"
                onClick={handleContinue}
                label={
                  activeStep === 0 || activeStep === 1
                    ? t("continue")
                    : t("save_and_apply")
                }
                style="filled"
                isDisabled={!canProceed()}
              />
            </div>
            {!(activeStep === 0 || activeStep === 1) && (
              <div className={styles.link_wrapper} onClick={handleApplyColumn}>
                <span className={styles.apply_without_save}>
                  {t("apply_but_dont_save")}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.saved_views_content}>
          <div className={`step_title ${styles.step_title}`}>
            {t("saved_views_content")}
          </div>
          <div className={styles.saved_view_card_wrapper}>
            {savedViews &&
              savedViews?.length >= 1 &&
              savedViews?.map((view, index) => (
                <SavedViewCard
                  key={index}
                  title={view?.name}
                  isDefault={view?.viewId === recentDefaultView?.viewId}
                  onEdit={() => handleEdit(view)}
                  onDelete={() => handleDelete(view.viewId)}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeViewModal;
