import React from "react";
import styles from "./LoadingAnimation.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import AILogoLight from "../../../assets/images/LightTheme/ai_logo_1.svg";
import AILogoDark from "../../../assets/images/DarkTheme/ai_logo_1.svg";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/store";

interface LoadingAnimationProps {
  isFinished?: boolean;
  isIcon?: boolean;
  customText?: string;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ isFinished, isIcon = true, customText = ""}) => {
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
    const { t } = useTranslation();

  return (
    <div className={styles.loader_anime}>
      {isIcon &&
        <div className={styles.ai_logo}>
          <img
            src={currentTheme === "light" ? AILogoLight : AILogoDark}
            className={styles.loading_icon}
            alt="logo"
          />
        </div>
      }
      <div className={`dots_anime ${styles.dots_anime}`}>
        {!customText ? t("retrieving_data") : customText}
        <span className={styles.animatedDots}>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>
    </div>
  );
};

export default LoadingAnimation;
