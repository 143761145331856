import React, { useState, useEffect } from "react";
import styles from "./ReportBuilder.module.scss";
import LoadingAnimation from "../../HATV/LoadingAnimation/LoadingAnimation";
import ReportCard from "../ReportCard/ReportCard";
import { useTranslation } from "react-i18next";
import ToastMessage from "../../common/ToastMessage/ToastMessage";

interface ReportBuilderProps {
  onClickCreateNewReportHandler: () => void;
  reportSummary: ReportData[];
  isReportTypeSelected: string;
  selectedABOs: SelectedItem[];
  isLoadingReport: boolean;
}

const ReportBuilder: React.FC<ReportBuilderProps> = ({
  onClickCreateNewReportHandler = () => {},
  reportSummary,
  isReportTypeSelected,
  selectedABOs,
  isLoadingReport,
}) => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");


  useEffect(() => {
    if (!isLoadingReport) {
      setToastMessage("Reports Successfully Generated");
      setShowToast(true);
      const toastTimer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => clearTimeout(toastTimer);
  }}, [isLoadingReport]);
  
  return (
    <>
      {!isLoadingReport ? (
        <div
          className={`report_builder_wrapper ${styles.report_builder_wrapper}`}
        >
          <ReportCard
            onClickCreateNewReport={onClickCreateNewReportHandler}
            reportSummary={reportSummary}
            isReportTypeSelected={isReportTypeSelected}
            selectedABOs={selectedABOs}
          />
          {showToast && (
            <ToastMessage
              message={toastMessage}
              subText=""
              toastType="success"
              showToast={showToast}
              setShowToast={setShowToast}
            />
          )}
        </div>
      ) : (
        <>
          <div className={styles.initial_text}>{t("creating_report")}</div>
          <LoadingAnimation isIcon={false} customText={t("analyzing_data")} />
        </>
      )}
    </>
  );
};

export default ReportBuilder;
