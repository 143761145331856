import React from "react";
import styles from "./Report.module.scss";
import ReportLandingPage from "./ReportLandingPage/ReportLandingPage";

const Report: React.FC = () => {
  // const [isGenerateReport, setIsGenerateReport] = useState<boolean>(true);
  return (
    <div
      className={`report_builder_container ${styles.report_builder_container}`}
    >
      <div className={styles.report_builder_wrapper}>
        <ReportLandingPage />
      </div>
    </div>
  );
};

export default Report;
