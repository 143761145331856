import React, { useEffect, useState, useCallback } from "react";
import styles from "./SearchBar.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/types";
import UserProfileDropdown from "../UserProfile/UserProfileDropdown";
import { useSearchABOMutation } from '../../../redux/services/globalSearchAPI';
import { DataItem } from "../../../types/types.d";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Icon from "../Icon/Icon";
import { withUtag } from "../../../utils/analytics";

interface SearchBarProps {
  placeholder: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder }) => {
  const history = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [searchABO, { isLoading: isLoadingSearchABO }] = useSearchABOMutation();
  const [searchData, setSearchData] = useState<DataItem[]>([]);
  const currentTheme = useSelector((state: RootState) => state.theme.currentTheme);
  // const { t } = useTranslation();
  // const searchIcon = currentTheme === "light" ? SearchLight : SearchDark;

  const searchDebounceABO = useCallback(
    debounce(async (term) => {
      if (term?.trim()?.length > 2) {
        const response = await searchABO({
          payload: { keyword: term?.trim() },
        });
        if (response && 'data' in response && response.data) {
          setSearchData(response.data.body);

          const searchData = {
            event_name: "onsite_search",
            search_keyword: term,
            search_results: response.data?.body?.length,
            search_type: "entered"
          };
          withUtag().then((utag) => utag.link(searchData));
        }
      }
    }, 600),
    []
  );
  const handleSearchABO = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchDebounceABO(term);
  };
  const handleSearchInput = async () => {
    searchDebounceABO(searchTerm);

  };
  const handleSearchTerm = () => {
    setSearchTerm("");
  }

  useEffect(() => {
    setSearchTerm("");
  }, [navigate]);

  return (
    <div className={`search_bar_container ${styles.search_bar_container}`}>
      <Icon
        name="search"
        size={24}
        color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
        onClick={handleSearchInput}
        className={styles.search_icon}
      />
      <input
        className={`search_input ${styles.search_input}`}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearchABO}
      />
      {searchData && searchTerm?.trim()?.length > 2 && (
        <div className={styles.search_dropdown_container}>
          <UserProfileDropdown
            searchTerm={searchTerm}
            handleSearchTerm={handleSearchTerm}
            data={searchData}
            isLoadingSearchABO={isLoadingSearchABO}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
