import React, { useState, useRef } from "react";
import { useAppSelector } from "../../redux/hooks";
import styles from "./Profile.module.scss";
import ProfileSubheader from "../common/ProfileSubheader/ProfileSubheader";
import SidePanel from "../common/Sidebar/Sidebar";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import GoalAndActionPlans from "./GoalAndActionPlans/GoalAndActionPlans";
import useScroll from "../../redux/slices/useScrollSlice";
import { SIDE_NAV_LINKS } from "../../constants/dataConstants";
import ABOGrowthCompanion from "./ABOGrowthCompanion/ABOGrowthCompanion";
import { useTranslation } from "react-i18next";
import belowSilverLight from "../../assets/images/LightTheme/belowSilverLight.svg"
import belowSilverDark from "../../assets/images/DarkTheme/belowSilverDark.svg"


const Profile: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const belowSilverImage =
    currentTheme === "light" ? belowSilverLight : belowSilverDark;
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("selectedLink") || "personal_information"
  );

  const [profileAwardCode, setProfileAwardCode] = useState<string | null>(null);

  const handleAwardCode = (data: string) => {
    setProfileAwardCode(data);
  };



  const refMap: Record<string, React.RefObject<HTMLDivElement>> = {
    contact_information: useRef<HTMLDivElement>(null),
    IBO_background: useRef<HTMLDivElement>(null),
    succession_plan: useRef<HTMLDivElement>(null),
    goals: useRef<HTMLDivElement>(null),
    documents: useRef<HTMLDivElement>(null),
    notes: useRef<HTMLDivElement>(null),
    // "key_kpis": useRef<HTMLDivElement>(null),
    abo_growth_companion: useRef<HTMLDivElement>(null),
    business_performance: useRef<HTMLDivElement>(null),
    structure: useRef<HTMLDivElement>(null),
    health_and_wellbeing: useRef<HTMLDivElement>(null),
    pipeline: useRef<HTMLDivElement>(null),
    qualifications: useRef<HTMLDivElement>(null),
    income: useRef<HTMLDivElement>(null),
    // "probability_modeling": useRef<HTMLDivElement>(null),
    // "compensation": useRef<HTMLDivElement>(null),
    business_information: useRef<HTMLDivElement>(null),
    goals_and_plans: useRef<HTMLDivElement>(null),
    personal_information: useRef<HTMLDivElement>(null),
  };

  const handleSectionChange = (sectionId: string) => {
    setActiveLink(sectionId);
    localStorage.setItem("selectedLink", sectionId);
  };

  const { handleClick } = useScroll(refMap, handleSectionChange);

  return (
    <section
      className={`profile_container ${currentTheme} ${styles.profile_container}`}
      data-testid="profile_testID"
    >
      <ProfileSubheader onAwardCodeChange={handleAwardCode} />

      {profileAwardCode && Number(profileAwardCode) < 310 ?
        (
          <div
            className={`abo_empty_state_container ${styles.below_silver_selected_wrapper}`}
          >
            <img
              src={belowSilverImage}
              alt={t("below_siver_selected")}
              className={styles.below_silver_image}
            />
            <p className={`below_silver_text ${styles.below_silver_text}`}>
              {t("below_silver")}
            </p>
          </div>
        ) :

        (<div className={styles.flex_wrapper}>
          <SidePanel
            activeLink={activeLink}
            onClickHandler={handleClick}
            elementRefMap={refMap}
            sideNavList={SIDE_NAV_LINKS}
            isSourceAP={false}
          />
          <div
            className={styles.content_wrapper}
            data-testid="content_wrapper_testID"
          >
            <div className={`content_inner ${styles.inner_wrapper}`}>
              <div
                className={
                  activeLink === "personal_information" ||
                    activeLink === "contact_information" ||
                    activeLink === "IBO_background" ||
                    activeLink === "succession_plan"
                    ? styles.active_section
                    : styles.hidden_section
                }
              >
                <PersonalInformation
                  activeLink={activeLink}
                  refMap={refMap}
                  onSectionChange={handleSectionChange}
                  isSourceAP={false}
                />
              </div>
              <div
                className={
                  activeLink === "goals_and_plans" ||
                    activeLink === "goals" ||
                    activeLink === "documents" ||
                    activeLink === "notes"
                    ? styles.active_section
                    : styles.hidden_section
                }
              >
                <GoalAndActionPlans
                  activeLink={activeLink}
                  refMap={refMap}
                  onSectionChange={handleSectionChange}
                />
              </div>
              <div
                className={
                  activeLink === "abo_growth_companion" ||
                    activeLink === "business_performance" ||
                    activeLink === "structure" ||
                    activeLink === "health_and_wellbeing" ||
                    activeLink === "pipeline" ||
                    activeLink === "qualifications" ||
                    activeLink === "income"
                    ? styles.active_section
                    : styles.hidden_section
                }
              >
                <ABOGrowthCompanion
                  activeLink={activeLink}
                  refMap={refMap}
                  onSectionChange={handleSectionChange}
                />
              </div>
            </div>
          </div>
        </div>)}
    </section>
  );
};

export default Profile;
