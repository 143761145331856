import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { sortAccountTableData } from "../../../utils/common";
import {
  SortOrder,
  IColumnStates
} from "../../../types/types";
import MyAccountTable from "./MyAccountTable/MyAccountTable";
import { aboColumnMapping } from '../../../constants/dataConstants';
import { useGetConfigKpiQuery } from "../../../redux/services/kpiSettingsByTypeAPI";
import { setCustomViewConfigKPIList } from "../../../redux/slices/customisedViewKpiList";
import { isEmpty } from "lodash";
import { RootState } from "../../../redux/store";

type ComponentProps = {
  data: any;
  viewDetails: any;
  isAllRecordFetched: boolean;
}

const AccountDetails = ({ data, viewDetails, isAllRecordFetched }: ComponentProps) => {
  const { t } = useTranslation();
  const viewId = useAppSelector((state: RootState) => state.viewIdDetails.viewId.defaultViewId);
  const dispatch = useDispatch();

  const defaultSortState = {
    "name": "nosort" as SortOrder,
    "currentAward.awardName": "nosort" as SortOrder,
    "groupGrowthIncentive.kpiValue": "nosort" as SortOrder,
    "fgiTracking.fgiTracking.baseline": "nosort" as SortOrder,
    "trackingAward.awardName": "nosort" as SortOrder,
    "monthlyRevenue.kpiValue": "nosort" as SortOrder,
    "annualRevenue.kpiValue": "nosort" as SortOrder,
    "monthlyContributor.kpiValue": "nosort" as SortOrder,
    "annualContributor.kpiValue": "nosort" as SortOrder,
    "lastMonthGroupPV.kpiValue": "nosort" as SortOrder,
    "currentMonthGroupPV.kpiValue": "nosort" as SortOrder,
    "lastMonthRubyPV.kpiValue": "nosort" as SortOrder,
    "currentMonthRubyPV.kpiValue": "nosort" as SortOrder,
    "currentMonthPersonalPV.kpiValue": "nosort" as SortOrder,
    // "qualifiedLeg.kpiValue": "nosort" as SortOrder,
    // "qualifiedMonth.kpiValue": "nosort" as SortOrder,
    "totalSPQualifiedMonthsLos.kpiValue": "nosort" as SortOrder,
    "trackingTwoTimeAward.trackingTwoTimeAward.awardCode": "nosort" as SortOrder,
  };
  const [tableDataList, setTableDataList] = useState<any[] | []>([]);
  const [columnState, setColumnState] = useState<IColumnStates>(defaultSortState);

  useEffect(() => {
    getSortedData(data).then((sortsData: any) => setTableDataList(sortsData));
  }, [data, columnState]);

  // Get All columns list
  const { data: configKpiResponse } = useGetConfigKpiQuery({
    type: "my-abo",
  });

  useEffect(() => {
    if (configKpiResponse && !isEmpty(configKpiResponse?.body)) {
      dispatch(setCustomViewConfigKPIList(configKpiResponse?.body));
    }
  }, [configKpiResponse]);

  const getSortedData = (tempData: any) => {
    let sortedData = tempData || [];

    for (const [column, order] of Object.entries(columnState)) {
      if (order !== 'nosort') {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return new Promise((resolve) => resolve(sortedData));
  };

  // Add new table if you want to display
  const tableColumnData = [t("Account_table_headers.IBO_Info"), t("Account_table_headers.Tracking_Award"), t("Account_table_headers.Monthly_Revenue"), t("Account_table_headers.Monthly_Contributors"), t("Account_table_headers.Annual_Revenue YTD"), t("Account_table_headers.Annual_Contributors")];
  const allTableColumnData = [t("Account_table_headers.IBO_Info"), t('Account_table_headers.PGI_PQs'), t('Account_table_headers.FGI_PQs'), t("Account_table_headers.Tracking_Award"), t("Account_table_headers.Monthly_Revenue"), t("Account_table_headers.Monthly_Contributors"), t("Account_table_headers.Annual_Revenue YTD"), t("Account_table_headers.Annual_Contributors"), t("Account_table_headers.Last_Month_GPV"), t("Account_table_headers.This_Month_GPV"), t("Account_table_headers.Last_Month_RPV"), t("Account_table_headers.This_Month_RPV"), t("Account_table_headers.This_Month_PPV"), t("Account_table_headers.Total_Qs"), t("Account_table_headers.ttci_ttca")]

  // Maintain order for the respective column of tableColumnData
  // add all expected table key path for the respective column to be sorted
  const tableColumnNameMapper = ["name", "trackingAward.awardName", "monthlyRevenue.kpiValue", "monthlyContributor.kpiValue", "annualRevenue.kpiValue", "annualContributor.kpiValue"];
  const allTableColumnNameMapper = ["name", "groupGrowthIncentive.kpiValue", "fgiTracking.fgiTracking.baseline", "trackingAward.awardName", "monthlyRevenue.kpiValue", "monthlyContributor.kpiValue", "annualRevenue.kpiValue", "annualContributor.kpiValue", "lastMonthGroupPV.kpiValue", "currentMonthGroupPV.kpiValue", "lastMonthRubyPV.kpiValue", "currentMonthRubyPV.kpiValue", "currentMonthPersonalPV.kpiValue", "totalSPQualifiedMonthsLos.kpiValue", "trackingTwoTimeAward.trackingTwoTimeAward.awardCode"];

  const kpis = viewDetails?.kpis;

  const tableColumnList = kpis?.map((kpi: any) => {
    return t(`Account_table_headers.${aboColumnMapping[kpi.code]?.columnName}`);
  });

  const columnNameMapperList = kpis?.map((kpi: any) => {
    return aboColumnMapping[kpi.code]?.valueMapping;
  });

  const filteredTableColumnData = viewId === 1 ? tableColumnData : [t("Account_table_headers.IBO_Info"), ...allTableColumnData.filter((col) =>
    tableColumnList?.includes(col))];

  const filteredColumnNamesMapper = viewId === 1 ? tableColumnNameMapper : ["name", ...allTableColumnNameMapper.filter((col) =>
    columnNameMapperList?.includes(col))];

  return <MyAccountTable tableData={tableDataList} isLoading={false} columnState={columnState} setColumnState={setColumnState} tableColumnData={filteredTableColumnData} tableColumnNameMapper={filteredColumnNamesMapper} isSortable={isAllRecordFetched} />
};

export default AccountDetails;
