import React from "react";
import styles from './KPIsPill.module.scss';
import Icon from "../../../../common/Icon/Icon";
import { useSelector } from "react-redux";


interface ITagProps {
    code: string;
    label?: string;
    onPillClick: (name: any) => void;
    isSummary: boolean,
    isSelected?: boolean,
    isSummarySelected?: boolean,
    key?:any
}

const KPIsPill: React.FC<ITagProps> = ({ code, label, onPillClick, isSummary, isSelected, isSummarySelected }) => {
    const currentTheme = useSelector((state: any) => state.theme.currentTheme);
    return (
        <div className={` ${isSelected || (isSummary && isSummarySelected) ? 'tag_container' : 'tag_unselected'} ${isSummary ? styles.tag_container : styles.small_tag_container}`} onClick={() => onPillClick(code)}>
            {isSelected && <span className={styles.circleCheck_tag}>
                <Icon
                    name="selected_tick_circle"
                    color={currentTheme === "light" ? "#fff" : "#000"}
                    className={styles.cross_circle}
                    size={16}
                />
            </span>}
            {/* Add MApper and display label usiing code */}
            <span className={`tag_label ${styles.tag_label}`}>{label}</span>
        </div>
    );
};

export default KPIsPill;
