
import React, { useEffect, useRef, useState } from "react";
import {
  SortOrder,
  IColumnStates
} from "../../../../types/types";
import isEmpty from "lodash/isEmpty";
import throttle from "lodash/throttle";
import styles from "./MyAccountTable.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import downloadLight from "../../../../assets/images/LightTheme/download_light.svg";
import downloadDark from "../../../../assets/images/DarkTheme/dowload_dark.svg";
import deleteLight from "../../../../assets/images/LightTheme/delete_light.svg";
import deleteDark from "../../../../assets/images/DarkTheme/delete_dark.svg";
import arrow_up_light from "../../../../assets/images/LightTheme/arrow_up_light.svg";
import arrow_up_dark from "../../../../assets/images/DarkTheme/arrow_up_dark.svg";
import arrow_down_light from "../../../../assets/images/LightTheme/arrow_down_light.svg";
import arrow_down_dark from "../../../../assets/images/DarkTheme/arrow_down_dark.svg";
import nosort_light from "../../../../assets/images/LightTheme/nosort_light.svg";
import nosort_dark from "../../../../assets/images/DarkTheme/nosort_dark.svg";
import downArrowPercent from "../../../../assets/images/downArrowPercent.svg";
import upArrowPercent from "../../../../assets/images/upArrowPercent.svg";
import downArrowPercentDark from "../../../../assets/images/downArrowPercentDark.svg";
import upArrowPercentDark from "../../../../assets/images/upArrowPercentDark.svg";
import { sortAccountTableData, getInitials } from "../../../../utils/common";
import { formatDate } from "../../../../utils/dateFormatter";
import { countryCodeMappings } from '../../../../constants/dataConstants';
import { useTranslation } from "react-i18next";
import { getTrackingAwardIconFromCode, getAwardNameFromCode, getTrackingAwardFromCode, getTrackingAwardColorFromCode, getCountryNameFromCode } from "../../../../utils/awardIconMapping";
import { useNavigate } from "react-router-dom";
import { setInitialUserDetails } from "../../../../redux/slices/userDetailsSlice";
import { useDispatch } from "react-redux";
import { CurrencyFormatter } from '../../../../utils/currencyFormatter';
import { NumberFormatter } from '../../../../utils/NumberFormatter';
import { saveStateToSessionStorage } from "../../../../utils/sessionStorageUtility";
// import Spinner from "../../../common/Spinner/Spinner";
import Icon from "../../../common/Icon/Icon";
import { withUtag } from "../../../../utils/analytics";

interface TableProps<T> {
  tableData: T[]; // Define columns as an array of keys of T
  isLoading: boolean,
  columnState: IColumnStates;
  setColumnState: (arg: IColumnStates) => void,
  rowLimit?: number,
  tableColumnData: string[],
  tableColumnNameMapper: string[],
  isSortable: boolean,
}
// Default column name which is a key of ITableColumnDetails
const MyAccountTable = <T,>({ tableData, isLoading, columnState, setColumnState, tableColumnData, tableColumnNameMapper, isSortable, rowLimit }: TableProps<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableDataList, setTableDataList] = useState<any[]>(tableData);
  const [displayData, setDisplayData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tableContainerRef = useRef<HTMLInputElement>(null);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);
  const [isViewmore, setViewMore] = useState<boolean>(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const selectedSortackgroundColor = currentTheme === "light" ? "#CACACA" : "#494949";
  const downloadIcon = currentTheme === "light" ? downloadLight : downloadDark;
  const deleteIcon = currentTheme === "light" ? deleteLight : deleteDark;
  const upArrowIcon = currentTheme === "light" ? upArrowPercent : upArrowPercentDark;
  const downArrowIcon = currentTheme === "light" ? downArrowPercent : downArrowPercentDark;
  const rowsPerPage = 10;

  useEffect(() => {
    setTableDataList(tableData);
  }, [tableData]);

  useEffect(() => {
    setTableDataList(tableData);
    setDisplayData(tableData?.slice(0, rowsPerPage));
  }, [tableData]);

  const loadMoreRows = () => {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    const moreRows = tableDataList?.slice(start, end) || [];
    // Only add if there are new rows to display
    if (moreRows.length > 0) {
      setDisplayData((prevDisplayData: any) => [...prevDisplayData, ...moreRows]);
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleScroll = throttle(() => {
    const container = tableContainerRef.current;

    if (container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollHeight = container.scrollHeight;

      // Add a small buffer (e.g., 1px) to account for rounding or fractional pixel issues
      if ((scrollPosition >= scrollHeight || scrollPosition >= scrollHeight - 3)) {
        loadMoreRows();
      }
    }
  }, 100);

  useEffect(() => {
    const container = tableContainerRef.current;
    container && container.addEventListener('scroll', handleScroll);

    return () => {
      container && container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleSort = (column: string) => {
    const tableHeaderNameIndex = tableColumnData.indexOf(column);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnStates;
    const currentKeySortOrder = columnState[name];
    Object.keys(columnState).forEach((keyName: string) => columnState[keyName as keyof IColumnStates] = 'nosort')
    let newOrder: SortOrder;

    switch (currentKeySortOrder) {
      case 'nosort':
        newOrder = 'asc';
        break;
      case 'asc':
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = 'nosort';
        break;
      default:
        newOrder = 'nosort';
    }

    const newSortState = {
      ...columnState,
      [name]: newOrder
    };

    setColumnState(newSortState);
    const currentTableSortedData = getSortedData();
    setTableDataList(currentTableSortedData);
    setCurrentPage(1);
  };

  const getColumnStateNameKey = (columnName: string) => {
    const tableHeaderNameIndex = tableColumnData.indexOf(columnName);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnStates;
    const sortname: SortOrder = columnState[name];
    return sortname;
  }

  const getSortedData = () => {
    let sortedData = [...tableData];

    for (const [column, order] of Object.entries(columnState)) {
      if (order !== 'nosort') {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return sortedData;
  };

  const addTableSortIcon = (column: string) => {

    let headerCellIcon = '';
    const tableHeaderNameIndex = tableColumnData.indexOf(column);
    const name = tableColumnNameMapper[tableHeaderNameIndex] as keyof IColumnStates;
    const isValidForTheCell = columnState.hasOwnProperty(name) && columnState[name];
    if (currentTheme === "light") {
      headerCellIcon = isValidForTheCell === "asc" ? arrow_up_light : isValidForTheCell === "desc" ? arrow_down_light : nosort_light;
    } else {
      headerCellIcon = isValidForTheCell === "asc" ? arrow_up_dark : isValidForTheCell === "desc" ? arrow_down_dark : nosort_dark;
    }
    return headerCellIcon;
  }

  const handleAboInfoClick = (data: any) => {
    if (data.aboNumber && data.affiliateCode) {
      dispatch(setInitialUserDetails({
        userDetails: {
          ...userDetails,
          affiliateCode: data.affiliateCode,
          aboNumber: data.aboNumber,
        }
      }))
      saveStateToSessionStorage({
        affiliateCode: data.affiliateCode,
        aboNumber: data.aboNumber,
      });
      setTimeout(() => {
        navigate(`/profile`);
      }, 1000);

      const accountData = {
        event_name: "account_view",
        abo_account: data.aboNumber,
        abo_country: data?.marketCountryCode?.toLowerCase(),
        abo_affiliate: data.affiliateCode
      };
      withUtag().then((utag) => utag.link(accountData));
    }
  };

  const buildTableCell = (colType: string, data: any) => {
    switch (colType) {

      case "info": return (
        <td className={`data_cell ${styles.data_cell}`} onClick={() => handleAboInfoClick(data)} data-testid="IBO_info_testID">
          <div className={styles.infoCell}>
            <div
              className={`info_logo_outer ${styles.info_logo_outer}`}
            >
              <label className={`info_logo_inner ${styles.info_logo_inner}`}>
                {getInitials(data.name || "")}
              </label>
            </div>
            <div className={`table_info ${styles.infoRightSection}`}>
              <label className={`labelInfo ${styles.labelInfo}`}>
                {data?.name || ""}
              </label>
              <label className={styles.labelInfoBottom}>
                {data?.marketCountryCode && data?.marketCountryCode !== "" ? `${data?.aboNumber} | ${getCountryNameFromCode(data?.marketCountryCode, t)}` : data?.aboNumber}
              </label>
            </div>
          </div>

        </td>);
      case "PGI":
        return (
          <>
            <td className={`data_cell ${styles.data_cell} ${styles.pgi_cols}`} >
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Baseline ? data?.Baseline : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("baseline")}</span>
              </span>
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Tracking ? data?.Tracking : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("tracking")}</span>
              </span>
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Actual ? data?.Actual : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("actual")}</span>
              </span>
            </td>
          </>
        );
      case "FGI":
        return (
          <>
            <td className={`data_cell ${styles.data_cell} ${styles.pgi_cols}`} >
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Baseline ? data?.Baseline : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("baseline")}</span>
              </span>
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Tracking ? data?.Tracking : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("tracking")}</span>
              </span>
              <span className={`data_item ${styles.data_item}`}>
                <span className={`data_value ${styles.data_value}`}>{data?.Actual ? data?.Actual : "-"}</span>
                <span className={`data_label ${styles.data_label}`}>{t("actual")}</span>
              </span>
            </td>
          </>
        );

      case "trackerAward": return (
        <td className={`data_cell ${styles.data_cell} ${styles.emptyDataCell}`}>
          {data?.awardCode ? <span
            className={styles.trackerAward}
          >
            {getAwardNameFromCode(data?.awardCode, t)}
          </span> : ""}
          {
            // data?.awardTagCode ?
            <div className={`award_icon ${styles.awardIcon}`} style={{ backgroundColor: currentTheme === "light" ? "#F4F4F4" : "#1C1C1E" }}>
              <span className={`award_icon_left ${styles.awardIconLeft}`}>
                {getTrackingAwardIconFromCode(data?.awardTagCode, currentTheme)}
              </span>
              <span className={`award_name ${styles.awardIconRight}`} style={{ color: getTrackingAwardColorFromCode(data?.awardTagCode, currentTheme) }}>
                {getTrackingAwardFromCode(data?.awardTagCode, t)}
              </span>
            </div>
            // : "-"
          }
        </td>);

      case "revenue": return (
        <td className={`data_cell ${styles.data_cell}`}>
          <span
            className={styles.revenue}
          >
            {data.revenue !== null && data.revenue !== undefined ?
              data?.currency !== "" ?
                String(CurrencyFormatter(data.currency, Math.round(data.revenue))).replace(/\.00$/, '')
                : NumberFormatter(userAffiliateCode, data.revenue)
              // :data.revenue
              : '-'
            }
          </span>

          {data.isPercentSection && <div className={styles.revPercentOuter}
            style={{ backgroundColor: data?.percentage ? data.percentage > 0 ? (currentTheme === 'light' ? "#F0FAE5" : "#1C1C1E") : (currentTheme === 'light' ? "#FDF4F4" : "#1C1C1E") : '' }}
          >
            {data?.percentage ? <>
              <img
                src={data?.percentage > 0 ? upArrowIcon : downArrowIcon}
                alt="percentage Indicator"
                id="percentIndicator"
                className={styles.revenueIndicator}
              />
              <span
                className={styles.revenuepercent}
                style={{ color: data.percentage > 0 ? (currentTheme === 'light' ? "#107F47" : "#B3D67B") : (currentTheme === 'light' ? "#D91734" : "#F16A81") }}
              >
                {Math.abs(Math.round(data.percentage)) + "%"}
              </span>
            </> : '-'
            }
          </div>}
        </td>);
      case "date": return <td className={`data_cell ${styles.data_cell}`}>{formatDate(data.updatedDate, countryCodeMappings[userAffiliateCode], 'dateTime')} </td>;
      case "docActions": return (<td className={`data_cell ${styles.data_cell}`}>
        <div
          className={`download_icon ${styles.download_icon}`}
        >
          <img src={downloadIcon} alt="download" />
        </div>
        <div
          className={styles.delete_icon}
        >
          <img src={deleteIcon} alt="delete" />
        </div>
      </td>);
      case "ttciCa": return (<td className={`data_cell ${styles.data_cell}`}>
        {data?.awardCode || data?.awardCode === 0 || data?.awardCode === '0' ?
          data?.awardCode === 0 || data?.awardCode === '0' ?
            <div className={styles.twoTimeAwardStyle}
              style={{ backgroundColor: currentTheme === 'light' ? "#F4F4F4" : "#1C1C1E" }}
            >
              <Icon name={currentTheme === 'light' ? 'no_tracker_icon_light' : 'no_tracker_icon_dark'} size={15} />
              <span
                className={styles.noTrackerText}
                style={{ color: currentTheme === 'light' ? "#707070" : "#AEAEB2" }}
              >
                {t('notTracking')}
              </span>
            </div> : data?.awardName?.toUpperCase()
          : "-"
        }

      </td>);

      default: return <div>Column type not matched</div>;

    }
  }

  return isLoading ? (
    <div className={styles.empty_state_document}>
      <div className={`table_no_data ${styles.empty_state_msg}`}>
        {t("loading")}
      </div>
    </div>
  ) : (
    isEmpty(tableDataList) ?
      <div className={styles.empty_state_document}>
        <div className={`table_no_data ${styles.empty_state_msg}`}>
          {t("noAboAvailable")}
        </div>
      </div>
      :
      <section
        className={`profile_table table_container ${styles.table_container}`}
        data-testid="table_section_testID"
      >
        <div className={styles.table_wrapper} ref={tableContainerRef}>
          <table id="data-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead className={styles.tbl_header_wrapper}>
              <tr className={`tbl_header ${styles.tbl_header}`}>
                {tableColumnData?.map((columnName: string, index: any) => (
                  <th
                    key={index}
                    className={`header_cell_myaccount ${styles.header_cell_myaccount}`}
                    data-testid="table_column_header_testID"
                    style={{
                      backgroundColor: getColumnStateNameKey(columnName) !== "nosort" ? selectedSortackgroundColor : ""
                    }}
                  >
                    <span>{t(columnName)}</span>
                    {isSortable && <img
                      src={addTableSortIcon(columnName)}
                      alt="sort"
                      style={{ display: getColumnStateNameKey(columnName) !== "nosort" ? "flex" : "" }}
                      onClick={() => handleSort(columnName)}
                      id={`${columnName}`}
                    />}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

              {displayData?.slice(0, rowLimit ? isViewmore ? Number.MAX_SAFE_INTEGER : rowLimit : Number.MAX_SAFE_INTEGER)
                ?.map(
                  (rowData: any, index: number) => (
                    <tr
                      key={`${index}${rowData?.aboNumber}`}
                      className={`tbl_data_row ${styles.tbl_data_row}`}
                      data-testid="Acc_table_row_testID"
                      style={{ width: "auto" }}
                    >

                      {buildTableCell('info', {
                        name: rowData.name,
                        aboNumber: rowData.aboNumber,
                        localName: rowData.localName,
                        ...(rowData?.marketCountryCode) && { marketCountryCode: rowData.marketCountryCode },
                        ...(rowData?.affiliateCode) && { affiliateCode: rowData.affiliateCode }
                      })}

                      {tableColumnNameMapper.includes("groupGrowthIncentive.kpiValue") && buildTableCell('PGI', {
                        ...(rowData?.groupGrowthIncentive?.baseline) && { Baseline: rowData?.groupGrowthIncentive?.baseline },
                        ...(rowData?.groupGrowthIncentive?.tracking) && { Tracking: rowData?.groupGrowthIncentive?.tracking },
                        ...(rowData?.groupGrowthIncentive?.actual) && { Actual: rowData?.groupGrowthIncentive?.actual },
                      })}
                      {(tableColumnNameMapper.includes("fgiTracking.fgiTracking.baseline") || tableColumnNameMapper.includes("fgiTracking.fgiTracking.tracking") || tableColumnNameMapper.includes("fgiTracking.fgiTracking.actual")) && buildTableCell('FGI', {
                        ...(rowData?.fgiTracking?.fgiTracking?.baseline) && { Baseline: rowData?.fgiTracking?.fgiTracking?.baseline },
                        ...(rowData?.fgiTracking?.fgiTracking?.tracking) && { Tracking: rowData?.fgiTracking?.fgiTracking?.tracking },
                        ...(rowData?.fgiTracking?.fgiTracking?.actual) && { Actual: rowData?.fgiTracking?.fgiTracking?.actual },
                      })}
                      {tableColumnNameMapper.includes("trackingAward.awardName") && buildTableCell('trackerAward', {
                        ...(rowData?.trackingAward?.awardName) && { awardName: rowData?.trackingAward?.awardName },
                        ...(rowData?.trackingAward?.awardCode) && { awardCode: rowData?.trackingAward?.awardCode },
                        ...(rowData?.trackingAward?.awardTagName) && { awardTagName: rowData?.trackingAward?.awardTagName },
                        ...(rowData?.trackingAward?.awardTagCode) && { awardTagCode: rowData?.trackingAward?.awardTagCode }
                      })}

                      {tableColumnNameMapper.includes("monthlyRevenue.kpiValue") && buildTableCell('revenue', {
                        ...((rowData?.monthlyRevenue?.kpiLocalValue !== null) || (rowData?.monthlyRevenue?.kpiValue)) && { revenue: rowData?.monthlyRevenue?.kpiLocalValue ? rowData?.monthlyRevenue?.kpiLocalValue : rowData?.monthlyRevenue?.kpiValue },
                        ...(rowData?.monthlyRevenue?.kpiVarianceValue) && { percentage: rowData?.monthlyRevenue?.kpiVarianceValue },
                        ...{ currency: rowData?.monthlyRevenue?.kpiLocalValue ? rowData?.monthlyRevenue?.kpiLocalDataType === 'currency' ? rowData?.monthlyRevenue?.kpiLocalCurrency : "" : rowData?.monthlyRevenue?.kpiDataType === 'currency' ? 'USD' : '' },
                        isPercentSection: rowData?.monthlyRevenue?.kpiLocalValue ? (rowData?.monthlyRevenue?.kpiLocalVarianceDataType === 'percentage') || '' : (rowData?.monthlyRevenue?.kpiVarianceDataType === 'percentage') || ''
                      })}
                      {tableColumnNameMapper.includes("monthlyContributor.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.monthlyContributor?.kpiValue !== null) && { revenue: rowData?.monthlyContributor?.kpiValue },
                        ...(rowData?.monthlyContributor?.kpiVarianceValue) && { percentage: rowData?.monthlyContributor?.kpiVarianceValue },
                        currency: rowData?.monthlyContributor?.kpiDataType === 'currency' ? rowData?.monthlyContributor?.kpiLocalCurrency || '' : '',
                        isPercentSection: rowData?.monthlyContributor?.kpiVarianceDataType === 'percentage',
                      })}
                      {tableColumnNameMapper.includes("annualRevenue.kpiValue") && buildTableCell('revenue', {
                        ...((rowData?.annualRevenue?.kpiLocalValue !== null) || (rowData?.annualRevenue?.kpiValue)) && { revenue: rowData?.annualRevenue?.kpiLocalValue ? rowData?.annualRevenue?.kpiLocalValue : rowData?.annualRevenue?.kpiValue },
                        ...(rowData?.annualRevenue?.kpiVarianceValue) && { percentage: rowData?.annualRevenue?.kpiVarianceValue },
                        ...{ currency: rowData?.annualRevenue?.kpiLocalValue ? rowData?.annualRevenue?.kpiLocalDataType === 'currency' ? rowData?.annualRevenue?.kpiLocalCurrency : "" : rowData?.annualRevenue?.kpiDataType === 'currency' ? 'USD' : '' },
                        isPercentSection: rowData?.annualRevenue?.kpiLocalValue ? (rowData?.annualRevenue?.kpiLocalVarianceDataType === 'percentage') || '' : (rowData?.annualRevenue?.kpiVarianceDataType === 'percentage') || ''
                      })}
                      {tableColumnNameMapper.includes("annualContributor.kpiValue") && buildTableCell('revenue', {
                        ...((rowData?.annualContributor?.kpiValue !== null) || (rowData?.annualContributor?.kpiValue)) && { revenue: rowData?.annualContributor?.kpiValue && Math.round(rowData?.annualContributor?.kpiValue) },
                        ...(rowData?.annualContributor?.kpiValue) && { percentage: rowData?.annualContributor?.kpiVarianceValue },
                        currency: rowData?.annualContributor?.kpiDataType === 'currency' ? rowData?.annualContributor?.kpiLocalCurrency || '' : '',
                        isPercentSection: rowData?.annualContributor?.kpiLocalVarianceDataType === 'percentage',
                      })}
                      {/* New cols addition */}
                      {tableColumnNameMapper.includes("lastMonthGroupPV.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.lastMonthGroupPV?.kpiValue !== null) && { revenue: rowData?.lastMonthGroupPV?.kpiValue },
                        ...(rowData?.lastMonthGroupPV?.kpiVarianceValue) && { percentage: rowData?.lastMonthGroupPV?.kpiVarianceValue },
                        currency: rowData?.lastMonthGroupPV?.kpiLocalCurrency || '',
                        isPercentSection: rowData?.lastMonthGroupPV?.kpiVarianceDataType === 'percentage',
                      })}
                      {tableColumnNameMapper.includes("currentMonthGroupPV.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.currentMonthGroupPV?.kpiValue !== null) && { revenue: rowData?.currentMonthGroupPV?.kpiValue },
                        ...(rowData?.currentMonthGroupPV?.kpiVarianceValue) && { percentage: rowData?.currentMonthGroupPV?.kpiVarianceValue },
                        currency: rowData?.currentMonthGroupPV?.kpiLocalCurrency || '',
                      })}
                      {tableColumnNameMapper.includes("lastMonthRubyPV.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.lastMonthRubyPV?.kpiValue !== null) && { revenue: rowData?.lastMonthRubyPV?.kpiValue },
                        ...(rowData?.lastMonthRubyPV?.kpiVarianceValue) && { percentage: rowData?.lastMonthRubyPV?.kpiVarianceValue },
                        currency: rowData?.lastMonthRubyPV?.kpiLocalCurrency || '',
                        isPercentSection: rowData?.lastMonthRubyPV?.kpiVarianceDataType === 'percentage',
                      })}
                      {tableColumnNameMapper.includes("currentMonthRubyPV.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.currentMonthRubyPV?.kpiValue !== null) && { revenue: rowData?.currentMonthRubyPV?.kpiValue },
                        ...(rowData?.currentMonthRubyPV?.kpiVarianceValue) && { percentage: rowData?.currentMonthRubyPV?.kpiVarianceValue },
                        currency: rowData?.currentMonthRubyPV?.kpiLocalCurrency || '',
                      })}
                      {tableColumnNameMapper.includes("currentMonthPersonalPV.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.currentMonthPersonalPV?.kpiValue !== null) && { revenue: rowData?.currentMonthPersonalPV?.kpiValue },
                        ...(rowData?.currentMonthPersonalPV?.kpiVarianceValue) && { percentage: rowData?.currentMonthPersonalPV?.kpiVarianceValue },
                        currency: rowData?.currentMonthPersonalPV?.kpiLocalCurrency || '',
                      })}
                      {/* {tableColumnNameMapper.includes("qualifiedLeg.ql") && buildTableCell('revenue', {
                      ...(rowData?.qualifiedLeg?.qualifierLeg !== null) && { revenue: rowData?.qualifiedLeg?.qualifierLeg },
                      ...(rowData?.qualifiedLeg?.qualifierLegPerformanceCount) && { percentage: rowData?.qualifiedLeg?.qualifierLegPerformanceCount },
                      currency: "",
                      isPercentSection: true,
                    })} */}
                      {/* {tableColumnNameMapper.includes("qualifiedMonth.qm") && buildTableCell('revenue', {
                      ...(rowData?.qualifiedMonth?.qualifiedMonth !== null) && { revenue: rowData?.qualifiedMonth?.qualifiedMonth },
                      ...(rowData?.qualifiedMonth?.qualifiedMonthPerformanceCount) && { percentage: rowData?.qualifiedMonth?.qualifiedMonthPerformanceCount },
                      currency: "",
                      isPercentSection: true,
                    })} */}
                      {tableColumnNameMapper.includes("totalSPQualifiedMonthsLos.kpiValue") && buildTableCell('revenue', {
                        ...(rowData?.totalSPQualifiedMonthsLos?.kpiValue !== null) && { revenue: rowData?.totalSPQualifiedMonthsLos?.kpiValue },
                        ...(rowData?.totalSPQualifiedMonthsLos?.kpiVarianceValue) && { percentage: rowData?.totalSPQualifiedMonthsLos?.kpiVarianceValue },
                        currency: rowData?.totalSPQualifiedMonthsLos?.kpiLocalCurrency || '',
                        isPercentSection: rowData?.totalSPQualifiedMonthsLos?.kpiVarianceDataType === 'percentage',
                      })}
                      {tableColumnNameMapper.includes("trackingTwoTimeAward.trackingTwoTimeAward.awardCode") && buildTableCell('ttciCa', {
                        ...(rowData?.trackingTwoTimeAward?.trackingTwoTimeAward?.awardName !== null) && { awardName: rowData?.trackingTwoTimeAward?.trackingTwoTimeAward?.awardName },
                        ...(rowData?.trackingTwoTimeAward?.trackingTwoTimeAward) && { awardCode: rowData?.trackingTwoTimeAward?.trackingTwoTimeAward.awardCode },
                        currency: rowData?.trackingTwoTimeAward?.kpiLocalCurrency || '',
                      })}
                    </tr>
                  )
                )}

              {/* {rowLimit && tableDataList?.length > rowLimit && (
                <div className={styles.viewmore_Section}>
                  <button
                    className={`view_more_text ${styles.viewmore_label}`}
                    onClick={() => setViewMore(!isViewmore)}
                    data-testid="view_more_testId"
                  >
                    {isViewmore ? t("view_less") : t("view_more")}
                  </button>
                </div>
              )} */}
              {/* {isMoreAccountListLoading &&
                <span className={styles.spinerStyling} >
                  <Spinner loadingSize={`large`} />
                </span>
              } */}
            </tbody>
          </table>
        </div>
      </section>
  );
};

export default MyAccountTable;
